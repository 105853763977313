import React from "react";

const NotificationSVG = ({ isActive }) => {
  return (
    <svg
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
      }}
    >
      <g opacity="0.7">
        <path
          d="M30.0312 25.1875C29.9408 25.1875 29.8504 25.1746 29.7626 25.15L0.700083 16.7542C0.285458 16.6341 0 16.2543 0 15.8229V10.0104C0 9.57899 0.285458 9.19924 0.700083 9.07911L29.7626 0.683279C30.0545 0.601904 30.371 0.656154 30.6138 0.839571C30.8566 1.02299 31 1.31103 31 1.61457V24.2187C31 24.5223 30.8566 24.8103 30.6138 24.9937C30.4433 25.1203 30.2392 25.1875 30.0312 25.1875ZM1.9375 15.0944L29.0625 22.9309V2.90236L1.9375 10.7389V15.0944Z"
          fill="currentColor"
        />
        <path
          d="M30.0312 25.8333C29.4965 25.8333 29.0625 25.3993 29.0625 24.8646V0.96875C29.0625 0.434 29.4965 0 30.0312 0C30.566 0 31 0.434 31 0.96875V24.8646C31 25.3993 30.566 25.8333 30.0312 25.8333Z"
          fill="currentColor"
        />
        <path
          d="M0.96875 18.0833C0.434 18.0833 0 17.6493 0 17.1146V8.71875C0 8.184 0.434 7.75 0.96875 7.75C1.5035 7.75 1.9375 8.184 1.9375 8.71875V17.1146C1.9375 17.6493 1.5035 18.0833 0.96875 18.0833Z"
          fill="currentColor"
        />
        <path
          d="M10.6563 24.5417C7.62996 24.5417 5.16675 22.0797 5.16675 19.0521C5.16675 18.4669 5.25329 17.9115 5.42379 17.4013C5.593 16.8937 6.13679 16.6186 6.64958 16.7904C7.15721 16.9596 7.43104 17.5085 7.26183 18.0162C7.15721 18.3262 7.10425 18.6762 7.10425 19.0521C7.10425 21.0115 8.69816 22.6042 10.6563 22.6042C12.2851 22.6042 13.7357 21.4572 14.1038 19.8774C14.2252 19.3582 14.747 19.0301 15.2676 19.1541C15.7894 19.2768 16.1123 19.7974 15.9909 20.3179C15.4097 22.8044 13.2164 24.5417 10.6563 24.5417Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default NotificationSVG;

import { Helmet } from "react-helmet";

const ChatWidget = () => {
  return (
    <Helmet>
      <script
        type="text/javascript"
        nonce={`{${process.env.REACT_APP_CHAT_WIDGET_NONCE}}`}
        src="https://desk.zoho.com/portal/api/web/inapp/865026000000355218?orgId=812181633"
        defer
      />
    </Helmet>
  );
};

export default ChatWidget;

import { Button, Card, Col, Divider, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { debounce } from "lodash";
import { AutomationRobotIcon, NotificationSVG } from "../../assets/svg";
import { LayoutComponent, SelectNumberModal } from "../../components";
import { useModal } from "../../hook/useModal";
import { handleCallAPI } from "../../redux/helpers";
import {
  getMessageAsync,
  getMessageStatisticsAsync,
  getPagination,
  searchMessageAsync,
  selectMessage,
  setPage,
} from "../../redux/messageReducer";
import { getFormsAsync, selectSettings } from "../../redux/settingsReducer";
import { selectUsers } from "../../redux/userReducer";
import { getVCardAsync, selectVCard } from "../../redux/vcardReducer";
import A2pPlanModal from "../Compliance/components/A2pPlanModal";
import WorkFlow from "./WorkFlow";
import SideBarMessage from "./components/SideBarMessage";
import "./styles.less";
// import {IoMdClose} from "reacet-icon/io"

const Message = () => {
  const { user } = useSelector(selectUsers);
  const {
    close: closePhoneNumber,
    show: showPhoneNumber,
    visible: phoneNumberModalVisible,
  } = useModal();

  const {
    close: closeA2pPlanModal,
    show: showA2pPlanModal,
    visible: a2pPlanModalVisible,
  } = useModal();

  const dispatch = useDispatch();
  const { message, messageStatistics } = useSelector(selectMessage);
 
  const { page, pageSize } = useSelector(getPagination);
  const { vcardData } = useSelector(selectVCard);
  const { forms } = useSelector(selectSettings);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [modal, setModal] = useState(false);

  const handleOkPhoneModal = () => {};
  const handleA2pPlanModal = () => {};

  const handleCancelPhoneModal = () => {
    closePhoneNumber();
  };

  const handleCancelA2pPlanModal = () => {
    closeA2pPlanModal();
  };

  const getSessionOfDay = () => {
    const time = new Date();
    const hours = time.getHours();
    // const minutes = time.getMinutes();

    if (hours >= 6 && hours < 12) {
      return "Good Morning";
    } else if (hours >= 12 && hours < 17) {
      return "Good Afternoon";
    } else if (hours >= 17 && hours < 21) {
      return "Good Evening";
    }
    return "Hello";
  };

  // useEffect(() => {
  //   if (user && !user?.phoneSystem?.length) {
  //     showPhoneNumber();
  //   }
  // }, [user]);

  useEffect(() => {
    dispatch(getMessageAsync({ page, pageSize, setLoading }));
    dispatch(getMessageStatisticsAsync());

    // //
    dispatch(getFormsAsync());
    dispatch(getVCardAsync());
  }, [dispatch]);

  const handleShowMore = () => {
    dispatch(getMessageAsync({ page: page + 1, pageSize, search: searchText }));
    dispatch(setPage(page + 1));
  };

  useEffect(() => {
    if (searchText.length >= 3) handleSearch();
  }, [searchText]);

  // useEffect(() => {
  //   console.log(searchText.length === 0)
  //   if (searchText.length === 0) handleSearch();
  // }, [searchText]);

  const fetchA2pRegStatus = async () => {
    const payload = {
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/a2p-registration/brandStatus`,
    };
    const a2pRegistration = await handleCallAPI(payload);
    if (a2pRegistration?.errors === "User not registered") {
      // setModal(true)
      showA2pPlanModal();
    }
  };

  useEffect(() => {
    // CHECKING FOR A2P REGISTRATION
    fetchA2pRegStatus();
  }, []);

  const handleSearch = debounce(() => {
    dispatch(searchMessageAsync({ page: 1, pageSize, search: searchText }));
  }, 1000);

  return (
    <>
      <LayoutComponent className="Message-page" title="Conversations">
        <Row style={{ height: "100%" }}>
          <Col span={8} lg={8} xs={24} className="md:w-full">
            <SideBarMessage
              data={message}
              handleShowMore={handleShowMore}
              className="Message-page-SideBarMessage"
              searchText={searchText}
              setSearchText={setSearchText}
              loading={loading}
            />
            <div className="md:hidden block ">
              <>
                <Divider className="mt-3 mb-5" />
                <WorkFlow vcardData={vcardData} forms={forms} />
              </>
            </div>
          </Col>
          <Col span={15} lg={15} md={23} className="pl-5 md:block hidden">
            <div className="flex justify-between items-center">
              <h1 className="mt-5">
                {getSessionOfDay()}, {user && user.firstName}
              </h1>
            </div>
            {forms && (!vcardData?.id || !forms?.length) ? (
              <>
                <Divider className="mt-3 mb-5" />
                <WorkFlow vcardData={vcardData} forms={forms} />
              </>
            ) : null}
            <Divider className="mt-3 mb-5" />
            <Card bordered={true} className="rounded-3xl mb-5">
              <Row gutter={16}>
                <Col span={8} className="">
                  <div className="flex flex-col justify-between items-center">
                    <div>
                      <h1 className="text-primary">
                        {!!messageStatistics ? (
                          messageStatistics?.totalFormSubmission
                        ) : (
                          <Spin />
                        )}
                      </h1>
                    </div>
                    <div>
                      <span>CONTACTS</span>
                    </div>
                  </div>
                </Col>
                <Col span={8} className="">
                  <div className="flex flex-col justify-between items-center">
                    <div>
                      <h1>
                        {!!messageStatistics ? (
                          messageStatistics?.totalUpdate
                        ) : (
                          <Spin />
                        )}
                      </h1>
                    </div>
                    <div>
                      <span>UPDATES SENT</span>
                    </div>
                  </div>
                </Col>
                <Col span={8} className="">
                  <div className="flex flex-col justify-between items-center ml-10">
                    <div>
                      <h1>
                        {!!messageStatistics ?<>{ (messageStatistics?.clickedPercent &&
                          messageStatistics?.clickedPercent.toFixed(2))} % </> : <Spin />}
                       
                      </h1>
                    </div>
                    <div>
                      <span>CLICK THROUGH</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
            <Row gutter={16}>
              <Col span={12} className="mb-4">
                <Card
                  bordered={true}
                  className="rounded-3xl min-h-48 max-h-50 pt-3 pl-2 pr-2"
                >
                  <div className="flex flex-row items-center mb-[1.2rem]">
                    <NotificationSVG />
                    <h2 className="font-bold text-xl ml-4">Send an Update</h2>
                  </div>
                  <span>
                    Broadcast a message to all of your subscribers or select a
                    filtered segment
                  </span>
                  <div className="">
                    <NavLink to="/updates/scheduled/new">
                      <Button type="primary" size="small" className="mt-3">
                        Send
                      </Button>
                    </NavLink>
                  </div>
                </Card>
              </Col>
              <Col span={12} className="mb-4">
                <Card
                  bordered={true}
                  className="rounded-3xl min-h-48 max-h-50 pl-2 pr-2"
                >
                  <div className="flex flex-row items-center">
                    <AutomationRobotIcon />
                    <h2 className="font-bold text-xl ml-4">
                      Create an Automation
                    </h2>
                  </div>
                  <span>
                    Create powerful drip campaigns & auto-responses based on a
                    variety of triggers
                  </span>
                  <div className="">
                    <NavLink to="/automation/new">
                      <Button type="primary" size="small" className="mt-3">
                        Set Up
                      </Button>
                    </NavLink>
                  </div>
                </Card>
              </Col>
            </Row>

            {vcardData?.id && forms?.length ? (
              <WorkFlow vcardData={vcardData} forms={forms} />
            ) : null}
          </Col>
        </Row>
        <SelectNumberModal
          handleCancel={handleCancelPhoneModal}
          handleClose={closePhoneNumber}
          handleOk={handleOkPhoneModal}
          visible={phoneNumberModalVisible}
        />

        <A2pPlanModal
          handleCancel={handleCancelA2pPlanModal}
          handleClose={closeA2pPlanModal}
          handleOk={handleA2pPlanModal}
          visible={a2pPlanModalVisible}
        />
      </LayoutComponent>
    </>
  );
};

export default Message;

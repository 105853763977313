import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAutomatedResponses,
  toggleKeyResponsesAsync,
} from "../../redux/automatedResponsesReducer";
import KeywordResponseList from "./KeywordResponseList";
import { useModal } from "../../hook/useModal";
import A2pPlanModal from "../../pages/Compliance/components/A2pPlanModal";

const KeywordResponseForm = () => {
  const { keyResponsesSettings, isLoading } = useSelector(selectAutomatedResponses);
  const dispatch = useDispatch();

  const {
    close: closeA2pPlanModal,
    show: showA2pPlanModal,
    visible: a2pPlanModalVisible,
  } = useModal();

  const handleCancelA2pPlanModal = () => {
    closeA2pPlanModal();
  };
  const handleA2pPlanModal = () => {};
  return (
    <div>
      <div>
        <div className="flex items-center justify-between md:justify-start">
          <h1 className="text-3xl md:text-4xl">Keyword Responses</h1>
          <Switch
            className="ml-5"
            checked={keyResponsesSettings?.isEnable}
            onChange={(checked) =>
              dispatch(toggleKeyResponsesAsync({ checked,showA2pPlanModal }))
            }
            loading={keyResponsesSettings.loading}
          />
        </div>
        <div className="max-w-4xl mt-2 md:mt-4">
          Create automated responses by checking incoming messages for hashtags,
          emojis, or Regex rules. Reorder the responses to determine which
          responses takes priority if a message contains more than one of the
          chosen hashtags, emojis or rules.
        </div>
      </div>
      <KeywordResponseList />
      <A2pPlanModal
        handleCancel={handleCancelA2pPlanModal}
        handleClose={closeA2pPlanModal}
        handleOk={handleA2pPlanModal}
        visible={a2pPlanModalVisible}
      />
    </div>
  );
};

export default KeywordResponseForm;

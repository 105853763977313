import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { set } from "lodash";
import React, { useEffect } from "react";

const PendingStatusModal = ({ visible, handleOk, handleCancel, data }) => {
    const [date, setDate] = React.useState("");

    useEffect(()=>{
      setDate(data ? new Date(data): "")
    },[data])
    
  return (
    <>
      <Modal
        key="TriggerModal"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        destroyOnClose={true}
        centered
        bodyStyle={{
          padding: "30px 40px",
        }}
        className="w-[400px]"
        width={700}
      >
        <div className="flex justify-end mb-4 p-1">
          <CloseOutlined className="cursor-pointer" onClick={handleCancel} />
        </div>
        <h2 className="text-base text-justify">
          Your account is in <b>PENDING</b> status for A2P registration from 
          {' '}
          {data
            ? date?.toLocaleString("en", {
                year: "numeric",
                month: "2-digit",
                day: "numeric",
              })
            : null}{" "}
          and usually takes 2-3 business days to be approved if all information
          that you entered was correct. If you would like to keep up with the
          status of your registration please <a href="/compliance" style={{color:'#D15D36'}}>click here</a>. If you have any
          questions about your registration please contact us at{' '}
          <a target="_blank" rel="noreferrer" href="mailto:support@kinsend.io" style={{color:'#D15D36'}}>support@kinsend.io</a>
        </h2>
      </Modal>
    </>
  );
};

export default PendingStatusModal;

import { Button, Col, Form, Modal, Row, Input, Select, Divider } from "antd";
import React, { useEffect } from "react";

import "./styles.less";
import { handleCallAPI } from "../../../../redux/helpers";

const ConfirmScheduleModal = ({
  visible,
  handleOk,
  handleCancel,
  dataSubmit,
  handleSendTest,
  loading,
}) => {
  const [cost, setCost] = React.useState(0);

  // console.log("dataSubmit", dataSubmit);

  // useEffect(() => {
  //   calculateCost(dataSubmit);
  // }, [dataSubmit]);

  const calculateCost = async (data) => {
    try {
      const temp = Object.keys(data.filter);
      const payload = {
        method: "GET",
        url: `${
          process.env.REACT_APP_API_BASE_URL
        }/updates/recipents?filter=${JSON.stringify(data.filter)}`,
      };

      // Number of contacts in the segment chosen * number of message segments * $0.01

      const res = await handleCallAPI(payload);

      const numberOfSegments = Math.floor(data.message.length / 160) + 1;

      const cost = (res.response * numberOfSegments * 0.01).toFixed(2);
      setCost(cost);
      // return  cost
    } catch (error) {
      console.log("error calculateCost", error);
    }
  };

  const getDate = (date) => {
    const inputDateStr = date;
    const inputDate = new Date(inputDateStr);

    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = inputDate.toLocaleDateString("en-US", options);

    const day = inputDate.getDate();
    let suffix;
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    const formattedDay = day + suffix;

    const [month, , year] = formattedDate.split(" ");
    const finalFormat = `${month} ${formattedDay}, ${year}`;
    return finalFormat;
  };

  if (!dataSubmit?.message) {
    return null;
  }
  return (
    <Modal
      key="ConfirmScheduleModal"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      destroyOnClose={true}
      centered
      className="ConfirmScheduleModal"
      width={840}
    >
      <h3 className="font-bold text-center text-2xl mb-9">Confirm Update</h3>
      <p className="text-center pb-5">Set up an Action to fire automatically</p>
      {/* <p className="text-center pb-5">
        {`Your update will cost an estimated $${cost}. It will be scheduled for `}
        <span style={{ fontWeight: "bold" }}>
          {getDate(dataSubmit.datetime)}
        </span>
        {` and be sent to `}
        <span style={{ fontWeight: "bold" }}>{dataSubmit.filter.key}.</span>
        {` Review your composed update below and consider sending a test`}
      </p> */}
      <div className="ConfirmScheduleModal-preview">
        <h4>PREVIEW</h4>
        <div className="phone-image-content-message">
          <div
            className="phone-image-content-message-box"
            dangerouslySetInnerHTML={{
              __html: dataSubmit?.message
                .replace(/<fname>/gi, `&lt;fname&gt;`)
                .replace(/<lname>/gi, `&lt;lname&gt;`)
                .replace(/<name>/gi, `&lt;name&gt;`)
                .replace(/<mobile>/gi, `&lt;mobile&gt;`)
                .replace(/<email>/gi, `&lt;email&gt;`)
                .replace(/<form>/gi, `&lt;form&gt;`),
            }}
            style={{ maxHeight: 100, overflowY: "auto", width: "100%" }}
          ></div>
        </div>
      </div>
      <Row justify="space-around" className="mt-12">
        <Col>
          <Form.Item noStyle>
            <Button
              disabled={Boolean(loading)}
              className="md:min-w-200"
              type="primary"
              size="large"
              onClick={handleSendTest}
              block
            >
              Send Test
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item noStyle shouldUpdate>
            <Button
              disabled={Boolean(loading)}
              className="md:min-w-200"
              type="primary"
              size="large"
              block
              loading={loading}
              onClick={handleOk}
            >
              Schedule
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmScheduleModal;

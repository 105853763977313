import React, { useEffect, useState, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  UploadFileModal,
  EmojiPicker,
  LayoutComponent,
  EditableText,
} from "../../../components";
import { AttachmentIcon, EmojiIcon } from "../../../assets/svg";
import SideBarMessage from "../components/SideBarMessage";
import SubscriberInfor from "../components/SubscriberInfor";
import MessageTimeline from "../components/MessageTimeline";
import { useModal } from "../../../hook/useModal";
import data from "@emoji-mart/data";
import { debounce } from "lodash";

import {
  selectMessage,
  getMessageAsync,
  getMessageDetailAsync,
  sendSmsAsync,
  resetSendSmsAsync,
  getPagination,
  setPage,
  searchMessageAsync,
} from "../../../redux/messageReducer";
import {
  getSubscriberByMessageIdAsync,
  selectSubscriptions,
} from "../../../redux/subscriptionReducer";
import { getTagsAsync } from "../../../redux/settingsReducer";

import "./styles.less";
import { useDimension } from "../../../hook/useDimension";
import { resetError, selectUserError } from "../../../redux/userReducer";
import { handleCallAPI } from "../../../redux/helpers";
import A2pPlanModal from "../../Compliance/components/A2pPlanModal";
import { notification } from "antd";

const MessageDetail = () => {
  let { messageId } = useParams();
  const dispatch = useDispatch();
  const { message, messageDetail, smsMessage } = useSelector(selectMessage);
  const { page, pageSize } = useSelector(getPagination);
  const { subscriberDetail } = useSelector(selectSubscriptions);
  const error = useSelector(selectUserError);
  const [showEmoji, setShowEmoji] = useState(() => false);
  const childRef = useRef();
  const [attachment, setAttachmentUrl] = useState({});
  const [defaultValueMessage, setDefaultValueMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [wordLimit, setWordLimit] = useState(160);

  const [directMessage, setDirectMessage] = useState("");
  const navigate = useNavigate();

  const [width] = useDimension();

  const {
    close: closeUpload,
    show: showUpload,
    visible: visibleUpload,
  } = useModal();

  const {
    close: closeA2pPlanModal,
    show: showA2pPlanModal,
    visible: a2pPlanModalVisible,
  } = useModal();

  const handleCancelA2pPlanModal = () => {
    closeA2pPlanModal();
  };

  const handleA2pPlanModal = () => {};

  const fetchA2pRegStatus = async () => {
    const payload = {
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/a2p-registration/brandStatus`,
    };
    const a2pRegistration = await handleCallAPI(payload);
    return a2pRegistration;
  };

  const handleSubmit = async () => {
    if (!directMessage && !attachment?.url) {
      return false;
    }

    // CHECHING A2P REGISTRATION

    const a2pRegStatus = await fetchA2pRegStatus();

    console.log("a2pRegStatus", a2pRegStatus);

    if (a2pRegStatus?.errors === "User not registered") {
      showA2pPlanModal();
      return;
    }

    if (a2pRegStatus?.response?.status !== "USER VERIFIED") {
      notification.error({
        title: "Error",
        message:
          "A2P 10DLC Registration is not approved yet. You cannot send messages until it is approved.",
      });
      return;
    }

    let msg = changeEmoji(directMessage);
    // .replace(/<span>/gi, "")
    // .replace(/<\/span>/gi, "")
    // .replace(/&lt;/gi, "<")
    // .replace(/&gt;/gi, ">") || ""
    console.log("Final message to send:", msg); // Final processed message

    let chunks = [];
    if (msg.length <= 160) {
      chunks = [msg];
    } else {
      for (let i = 0; i <= parseInt(msg.length / 160); i++) {
        let to = 160 * i;
        let from = 160 * (i + 1);
        chunks.push(msg.slice(to, from));
      }
    }
    for (let i = 0; i < chunks.length; i++) {
      const params = {
        formSubmissionId: subscriberDetail.id,
        message: chunks[i],
        fileUrl: attachment?.url || "",
      };
      dispatch(sendSmsAsync(params));
    }
  };

  const changeEmoji = (value) => {
    const words = value.split(" ");

    const wordsWithEmojis = words.map((word) => {
      if (word.startsWith(":") && word.endsWith(":")) {
        const emojiKeyword = word.slice(1, -1);
        const emoji = data.emojis[emojiKeyword]?.skins?.[0]?.native;

        if (emoji) {
          return emoji;
        }
      }

      return word;
    });

    // Join the words back into a string
    let message = wordsWithEmojis.join(" ");
    return message;
  };

  const hanldeChangeMessage = (messageValue) => {
    const msg = changeEmoji(messageValue);
    setDirectMessage(msg);
  };

  const handleChangeEmoji = (emojiObj) => {
    // let message = form.getFieldValue("message") || "";
    // form.setFieldsValue({
    //   message: message + emojiObj.native,
    // });
    // childRef.current.triggerUpdateText(emojiObj.native);
    setDirectMessage(`${directMessage}` + `${emojiObj.native}`);
    if (wordLimit === 2) {
      setWordLimit(160);
    } else if (wordLimit === 1) {
      setWordLimit(159);
    } else {
      setWordLimit(wordLimit - 2);
    }
    setShowEmoji(false);
  };

  const handleUploadFile = (value) => {
    setAttachmentUrl(value);
    closeUpload();
  };

  const handleShowMore = () => {
    dispatch(getMessageAsync({ page: page + 1, pageSize, search: searchText }));
    dispatch(setPage(page + 1));
  };

  const handleBackToList = () => {
    navigate("/message");
  };

  useEffect(() => {
    dispatch(getMessageAsync({ page, pageSize, search: searchText }));
  }, [dispatch]);

  useEffect(() => {
    if (error.length === 0 || !error) return;
    if (error === "Contact not found!") {
      // Beautify error for user
      notification.error({ message: error });
      dispatch(resetError());
      navigate("/message");
    }
  }, [error]);

  useEffect(() => {
    if (searchText.length >= 3) handleSearch();
    // handleSearch()
  }, [searchText]);

  useEffect(() => {
    if (searchText.length === 0) handleSearch();
  }, [searchText]);

  const handleSearch = debounce(() => {
    dispatch(searchMessageAsync({ page: 1, pageSize, search: searchText }));
  }, 1000);

  useEffect(() => {
    if (messageId) {
      dispatch(getMessageDetailAsync(messageId));
      dispatch(getSubscriberByMessageIdAsync(messageId));
      setDirectMessage("");
      setDefaultValueMessage("");
    }

    const timer = setInterval(() => {
      dispatch(getSubscriberByMessageIdAsync(messageId));
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [messageId, dispatch]);

  useEffect(() => {
    dispatch(getTagsAsync());
  }, []);

  useEffect(() => {
    if (smsMessage) {
      dispatch(getMessageDetailAsync(messageId));
      dispatch(resetSendSmsAsync());
      setAttachmentUrl({});
      setDefaultValueMessage("");
      setDirectMessage("");
    }
  }, [smsMessage, dispatch]);
  // console.log("###messageDetail", messageDetail);

  return (
    <LayoutComponent
      className="messageDetail-page"
      title={`${subscriberDetail?.firstName || ""} ${
        subscriberDetail?.lastName || ""
      }`}
    >
      <div className="flex min-h-full">
        <div className="w-350 md:block hidden">
          <SideBarMessage
            handleShowMore={handleShowMore}
            data={message}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
        <div className="flex-1 flex messageDetail-body flex-col">
          <div
            className="md:hidden flex items-center back-button px-4 py-2"
            onClick={handleBackToList}
          >
            <ArrowLeftOutlined />
            <span className="ml-3 py-3 font-bold	">View Message List</span>
          </div>
          <MessageTimeline data={messageDetail} className="flex-1" />
          <div
            className="custom-textarea-wrap messageDetail-page-send-message border-left"
            style={{ marginBottom: "-60px" }}
          >
            <EditableText
              defaultValue={defaultValueMessage}
              onChange={hanldeChangeMessage}
              ref={childRef}
              className="messageDetail-EditableText"
              handleEnterSubmit={handleSubmit}
              isDropdownTop
              value={directMessage}
              setValue={setDirectMessage}
              setAttachmentUrl={setAttachmentUrl}
              wordLimit={wordLimit}
              setWordLimit={setWordLimit}
            />
            <div className="textarea-actions">
              <AttachmentIcon onClick={showUpload} />
              {/* <EmojiIcon onClick={() => setShowEmoji(true)} />
              {showEmoji && <EmojiPicker onEmojiSelect={handleChangeEmoji} />} */}
              <EmojiPicker onEmojiSelect={handleChangeEmoji} />
              <UploadFileModal
                visible={visibleUpload}
                handleOk={handleUploadFile}
                handleCancel={closeUpload}
              />
            </div>
            <span className="messageDetail-page-attachment">
              {attachment?.url}
            </span>
          </div>
        </div>
        {width > 1166 ? (
          <SubscriberInfor data={subscriberDetail} />
        ) : (
          showInfo && (
            <>
              <div className="md:block">
                <div className="info-mobile-popup">
                  <div className="info-mobile-popup-content max-w-350">
                    <SubscriberInfor data={subscriberDetail} />
                    <div
                      className="info-mobile-popup-backdrop"
                      onClick={() => setShowInfo(!showInfo)}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          )
        )}

        {width <= 1166 ? (
          <div
            className="layout-mobile-info"
            onClick={() => setShowInfo(!showInfo)}
          >
            {/* <MenuUnfoldOutlined /> */}
            <div className="menu-hamburger-info">
              <div className="menu-hamburger-line-info" />
            </div>
          </div>
        ) : null}
      </div>
      <A2pPlanModal
        handleCancel={handleCancelA2pPlanModal}
        handleClose={closeA2pPlanModal}
        handleOk={handleA2pPlanModal}
        visible={a2pPlanModalVisible}
      />
    </LayoutComponent>
  );
};

export default memo(MessageDetail);

import React, { useEffect, useState } from "react";
import { Form, Select, Input, Space, Button, notification, Switch } from "antd";
import { getTags, updateUser } from "../../redux/userReducer";

const EditUser = ({
  userData,
  setInitialTagsIds,
  initialTagsIds,
  setEditOpen,
  setUserData,
  editOpen,
}) => {
  const { metaData } = userData;
  const parsedMetaData =
    Object.keys(userData).length > 0 ? JSON?.parse(metaData) : {};
  const [tags, setTags] = useState([]);
  const [form] = Form.useForm();
  const [isSubscribed, setIsSubscribed] = useState(userData?.isSubscribed);

  const handleUpdateUser = async () => {
    form.validateFields().then(async (values) => {
      const phoneNumber = {
        phone: values.phoneNumber.slice(1),
        code: parseInt(values.phoneNumber.slice(0, 1)),
        short: "US",
        status: "VERIFY",
        isPrimary: true,
      };

      const payload = {
        ...values,
        phoneNumber: phoneNumber,
        tagIds: initialTagsIds?.map((obj) => obj?.id),
        metaData: metaData,
        isContactArchived: userData?.isContactArchived,
        isContactHidden: userData?.isContactHidden,
        isFacebookContact: userData?.isFacebookContact,
        isSubscribed: isSubscribed,
        isVip: userData?.isVip,
        isConversationArchived: userData?.isConversationArchived,
        isConversationHidden: userData?.isConversationHidden,
      };
      try {
        const response = await updateUser(userData?.id, payload);
        setEditOpen(false);
        if (response?.errors) {
          notification.error({
            title: "Error",
            message: response.errors[0],
          });
        } else {
          notification.success({
            title: "Updated",
            message: "Contact Updated Successfully",
          });
        }
      } catch (error) {
        console.log("error", error);
        setEditOpen(false);
      }
    });
  };

  function handleTagsChange(tagName) {
    const filteredIds = tags
      ?.filter((tag) => tagName.includes(tag?.name))
      .map((tag) => ({
        id: tag.id,
        name: tag.name,
      }));
    setInitialTagsIds(filteredIds);
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 12 },
    },
  };

  const initialValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    email: userData?.email,
    phoneNumber: userData?.phoneNumber?.code + userData?.phoneNumber?.phone,
    metaData: parsedMetaData,
  };

  const getAllTags = async () => {
    try {
      const { response } = await getTags();
      setTags(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTags();
  }, [editOpen]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [userData]);

  return (
    <>
      <Form
        layout="vertical"
        {...formItemLayout}
        form={form}
        name="control-hooks"
        initialValues={initialValues}
        style={{
          height: "600px",
          overflowY: "scroll",
          border: "1px solid #0000001a",
          padding: "20px",
        }}
      >
        <Form.Item
          label="First name"
          name="firstName"
          className="flex"
          rules={[{ required: true, message: "Please enter your first name" }]}
        >
          <Input className="sm:w-100 w-50" />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          className="flex"
          rules={[{ required: true, message: "Please enter your last name" }]}
        >
          <Input className="sm:w-100 w-50" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          className="flex"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input className="sm:w-100 w-50" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phoneNumber"
          className="flex"
          rules={[
            { required: true, message: "Please enter your phone number" },
            {
              //   pattern: /^\d{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
        >
          <Input className="sm:w-100 w-50" />
        </Form.Item>
        {Object.keys(userData).length > 0
          ? Object?.entries(parsedMetaData)?.map(([key, value]) => (
              <Form.Item
                label={key}
                key={`metaData.${key}`}
                name={["metaData", key]}
                className="flex"
                required={false}
              >
                <Input className="sm:w-100 w-50" />
              </Form.Item>
            ))
          : null}
        <Form.Item className="flex items-center ">
          <label className="font-sm font-medium uppercase leading-10 tracking-[0.155em]">
            Subscribe
          </label>
          <Switch
            className="ml-5"
            checked={isSubscribed}
            onChange={() => setIsSubscribed(!isSubscribed)}
          />
        </Form.Item>
        <Form.Item label="Tags">
          <Select
            style={{ width: "97%", padding: "0px !important",borderRadius:"5px" }}
            mode="multiple"
            value={initialTagsIds?.map((tag) => tag.name)}
            showArrow={true}
            size="large"
            placeholder="select tags"
            onChange={handleTagsChange}
            optionLabelProp="label"
          >
            {tags?.map((tag, index) => (
              <Select.Option
                style={{ padding: "0px !important" }}
                value={tag?.name}
                label={tag?.name}
                key={tag?.name + "-" + index}
              >
                <Space>{tag?.name}</Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <div className="flex justify-end">
        <Button
          type="primary"
          className="my-3"
          size="middle"
          onClick={handleUpdateUser}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default EditUser;

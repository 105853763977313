import React from "react";

const HistorySVG = ({ isActive }) => {
  return (
    <svg
      width="33"
      height="38"
      viewBox="0 0 33 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
      }}
    >
      <g opacity="0.7">
        <path
          d="M2.43903 6.09841C3.5234 6.28231 4.45307 6.45297 5.38568 6.5942C5.93523 6.67659 6.26968 6.95023 6.17833 7.51812C6.08698 8.086 5.67298 8.23165 5.1308 8.14338C3.75176 7.91828 2.37126 7.71673 0.992226 7.49605C0.136224 7.35923 -0.0906685 7.09441 0.0301442 6.26466C0.239356 4.8376 0.495715 3.41642 0.722607 1.99818C0.805113 1.47591 1.10862 1.17284 1.61986 1.25376C2.16647 1.34056 2.37126 1.73484 2.27402 2.2836C2.15763 2.9574 2.06333 3.63416 1.94105 4.43155C2.20625 4.23735 2.39483 4.10641 2.57458 3.96518C13.6893 -4.76197 30.388 1.70836 32.7306 15.648C33.1549 18.1285 33.0774 20.6687 32.5026 23.1189C31.9279 25.569 30.8676 27.8794 29.3843 29.9138C27.9009 31.9481 26.0246 33.6652 23.866 34.9638C21.7073 36.2624 19.3101 37.1161 16.8157 37.4746C13.8664 37.9109 10.855 37.6231 8.04209 36.6361C7.99641 36.6199 7.94632 36.614 7.89475 36.5993C7.35257 36.4213 7.02549 36.0755 7.2347 35.4959C7.44392 34.9162 7.89033 34.9354 8.39421 35.1119C10.6398 35.9046 13.0253 36.2251 15.4008 36.0531C17.7763 35.8811 20.0906 35.2204 22.1981 34.1125C24.3057 33.0046 26.161 31.4735 27.6476 29.6153C29.1341 27.757 30.2198 25.6119 30.8363 23.3146C31.4528 21.0174 31.5869 18.6176 31.2301 16.2661C30.8733 13.9146 30.0333 11.6622 28.763 9.6504C27.4927 7.6386 25.8194 5.91087 23.8483 4.57576C21.8772 3.24065 19.6509 2.32702 17.3093 1.89226C12.1527 0.930094 7.48664 2.08204 3.3466 5.31279C3.06519 5.532 2.80589 5.77916 2.43903 6.09841Z"
          fill="currentColor"
        />
        <path
          d="M2.35803 21.746C2.35803 19.0243 2.35803 16.3026 2.35803 13.5809C2.35803 11.5212 3.68403 10.1795 5.76141 10.1765C11.3355 10.1687 16.9086 10.1687 22.4807 10.1765C24.6332 10.1765 25.9268 11.4815 25.9283 13.6059C25.9283 17.6017 25.9283 21.5979 25.9283 25.5947C25.9283 27.7176 24.676 28.9917 22.5588 28.9961C17.7469 29.0049 12.9336 28.9858 8.12021 29.0182C7.59354 29.043 7.07974 29.189 6.61889 29.4448C5.68627 29.9318 4.8067 30.5188 3.89766 31.0499C2.89285 31.6384 2.36098 31.3441 2.35951 30.1995C2.35951 27.3807 2.35951 24.5614 2.35951 21.7416L2.35803 21.746ZM3.94628 29.1873C4.55476 28.8269 5.06306 28.5812 5.50653 28.2517C6.37726 27.6058 7.3305 27.441 8.39867 27.4499C13.0367 27.4896 17.6806 27.4734 22.3142 27.4763C23.8111 27.4763 24.3445 26.9408 24.3445 25.4402C24.3445 21.517 24.3445 17.5938 24.3445 13.6706C24.3445 12.3009 23.7964 11.7581 22.4291 11.7581C16.9071 11.7581 11.3851 11.7581 5.86307 11.7581C4.48551 11.7581 3.94775 12.2906 3.94775 13.6706C3.94775 16.8808 3.94775 20.0909 3.94775 23.3011L3.94628 29.1873Z"
          fill="currentColor"
        />
        <path
          d="M14.146 16.4689C11.9125 16.4689 9.67842 16.4689 7.44388 16.4689C6.89286 16.4689 6.37719 16.4292 6.32121 15.723C6.27701 15.1639 6.64927 14.8834 7.43799 14.8815C11.9071 14.8815 16.3762 14.8815 20.8452 14.8815C21.3918 14.8815 21.9296 14.9506 21.965 15.6465C21.9974 16.2879 21.5642 16.4689 20.9985 16.4689C18.7129 16.466 16.4287 16.466 14.146 16.4689Z"
          fill="currentColor"
        />
        <path
          d="M14.1489 18.8271C16.384 18.8271 18.6175 18.8271 20.8511 18.8271C21.4051 18.8271 21.9148 18.8772 21.9708 19.5789C22.0135 20.135 21.6364 20.4131 20.8467 20.4131C16.4267 20.4131 12.0067 20.4131 7.58675 20.4131C7.48804 20.4131 7.3908 20.4131 7.29209 20.4131C6.74696 20.3896 6.29759 20.2233 6.32411 19.573C6.34769 18.9684 6.76611 18.8286 7.29651 18.8286C9.58213 18.8296 11.8663 18.8291 14.1489 18.8271Z"
          fill="currentColor"
        />
        <path
          d="M12.1895 22.77C13.8102 22.77 15.422 22.7788 17.0382 22.77C17.604 22.77 18.0592 22.9333 18.0533 23.5483C18.0474 24.1633 17.5819 24.3118 17.022 24.3104C13.7895 24.2986 10.5566 24.2986 7.32311 24.3104C6.81482 24.3104 6.36103 24.2324 6.3242 23.6086C6.28442 22.9583 6.694 22.7788 7.26565 22.7803C8.90988 22.7744 10.5512 22.77 12.1895 22.77Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default HistorySVG;

import React from "react";

const TwitterSVG = ({ isActive }) => {
  const color = isActive ? "#D15D36" : "#BABABA";
  return (
    <svg
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "inline-block",
      }}
    >
      <path
        d="M26 1.01143C24.9118 1.77901 23.7069 2.3661 22.4318 2.75006C21.7474 1.96314 20.8379 1.4054 19.8262 1.15225C18.8145 0.899109 17.7494 0.962786 16.7751 1.33467C15.8008 1.70655 14.9641 2.3687 14.3784 3.23156C13.7926 4.09442 13.486 5.11635 13.5 6.15915V7.29552C11.503 7.3473 9.52418 6.9044 7.73978 6.00625C5.95539 5.10811 4.42081 3.7826 3.27273 2.14779C3.27273 2.14779 -1.27273 12.3751 8.95455 16.9205C6.61424 18.5091 3.82631 19.3057 1 19.1932C11.2273 24.8751 23.7273 19.1932 23.7273 6.12506C23.7262 5.80853 23.6958 5.49278 23.6364 5.18188C24.7961 4.03812 25.6146 2.59406 26 1.01143V1.01143Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TwitterSVG;

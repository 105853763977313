import React, { useEffect, useRef, useState } from "react";
import { format, isBefore, isAfter } from "date-fns";
import { Input, Divider, Dropdown, Menu, Button, Spin } from "antd";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import { DownOutlined } from "@ant-design/icons";

import { EditIcon, SearchIcon } from "../../../../assets/svg";

import "./styles.less";
import { getUpdates } from "../../../../redux/updatesReducer";

const SideBarUpdate = ({ data, className, loadMore }) => {
  const [dataShow, setDataShow] = useState(data);
  const [filter, setFilter] = useState("All Updates");
  const [searchText, setSearchText] = useState("");
  const handleClick = ({ key }) => {
    setFilter(key);
  };
  const scrollView = useRef();
  const scrollViewDiv = useRef();

  const menu = (
    <Menu
      onClick={handleClick}
      items={[
        {
          label: "All Updates",
          key: "All Updates",
        },
        {
          label: "Sent Updates",
          key: "Sent Updates",
        },
        {
          label: "Scheduled Updates",
          key: "Scheduled Update",
        },
      ]}
    />
  );

  useEffect(() => {
    let result = data.filter((item) => item.message.includes(searchText));
    if (filter === "Sent Updates") {
      result = result.filter((item) => {
        return (
          item.triggerType === "Once" &&
          isBefore(new Date(item.datetime), new Date())
        );
      });
    } else if (filter === "Scheduled Update") {
      result = result.filter((item) => {
        return (
          item.triggerType !== "Once" ||
          (item.triggerType === "Once" &&
            isAfter(new Date(item.datetime), new Date()))
        );
      });
    }
    setDataShow(result);
  }, [data, searchText, filter]);

  useEffect(() => {
    setDataShow(data);
  }, [data]);

  return (
    <div
      className={classNames("SideBarUpdate", className)}
      // style={{ height: "100%", overflow: "auto" }}
      ref={scrollView}
    >
      <div className="md:hidden flex flex-row justify-center items-center mb-3">
        <NavLink to="/updates/scheduled/new">
          <Button type="primary" size="large" className="w-48	">
            New Update
          </Button>
        </NavLink>
      </div>
      <div className="flex justify-between items-center">
        <div className="SideBarUpdate-input flex flex-auto justify-between items-center pr-2">
          <Input
            className="SideBarUpdate-input"
            placeholder="Search Existing Updates..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <SearchIcon className="cursor-pointer" />
        </div>
        <NavLink
          to="/updates/scheduled/new"
          className="ml-3 SideBarUpdate-edit-btn"
        >
          <EditIcon />
        </NavLink>
      </div>
      <div className="flex justify-between items-center mt-5">
        <span>{dataShow?.length} UPDATES</span>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <div className="text-primary flex items-center">
              View {filter}
              <DownOutlined className="ml-2" />
            </div>
          </a>
        </Dropdown>
      </div>
      <Divider className="mt-3 mb-5" />
      <div className="SideBarUpdate-list">
        {dataShow?.length ? (
          <div
            id={"scrollableDiv"}
            className="SideBarMessage-list overflow-auto"
            ref={scrollViewDiv}
          >
            <InfiniteScroll
              dataLength={dataShow.length}
              next={loadMore}
              hasMore={true}
              scrollableTarget={"scrollableDiv"}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {dataShow.map((item) => {
                return (
                  <NavLink
                    to={`/updates/detail/${item.id}`}
                    className="SideBarUpdate-item"
                    key={`sidebar-update-item-${item?.id}`}
                  >
                    <h3
                      className="text-ellipsis overflow-hidden truncate pl-2 pr-2"
                      dangerouslySetInnerHTML={{
                        __html: item.message
                          .replace(/<fname>/gi, `&lt;fname&gt;`)
                          .replace(/<lname>/gi, `&lt;lname&gt;`)
                          .replace(/<name>/gi, `&lt;name&gt;`)
                          .replace(/<mobile>/gi, `&lt;mobile&gt;`)
                          .replace(/<email>/gi, `&lt;email&gt;`)
                          .replace(/<form>/gi, `&lt;form&gt;`),
                      }}
                    >
                      {/* {truncate(item.message, {
                  length: 30,
                  omission: "...",
                })} */}
                    </h3>
                    <span>
                      {item.progress === "Scheduled" ? "Schedule" : "Sent"}{" "}
                      {format(new Date(item.datetime), "MM/dd/yyyy hh:mm aa")}.
                      to {item.triggerType}
                    </span>
                  </NavLink>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="flex items-center justify-center h-[300px]">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBarUpdate;

import { ConfigProvider } from "antd-country-phone-input";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import en from "world_countries_lists/data/countries/en/world.json";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import SignUpConfirmation from "./pages/SignUp/SignupConfirmation";
import Dashboard from "./pages/Dashboard";
import Message from "./pages/Message";
import MessageDetail from "./pages/Message/MessageDetail";
import FormManage from "./pages/Settings/Form";
import AddNewForm from "./pages/Settings/Form/AddNewForm";
import TagsManage from "./pages/Settings/TagsManage";
import PublicFormSumission from "./pages/Public/FormSubmission";
import PublicThankYouSubmission from "./pages/Public/ThankYouSubmission";
import ForgotPasswordConfirmation from "./pages/Public/ForgotPasswordConfirmation";
import Automation from "./pages/Automation";
import AddNewAutomation from "./pages/Automation/AddNewAutomation";
import UpdatesDashboard from "./pages/Updates";
import AddNewUpdates from "./pages/Updates/AddNewUpdates";
import EditUpdates from "./pages/Updates/UpdatesDetail";
import { STORAGE_AUTH_KEY } from "./utils/constants";
import useLocalStorage from "./hook/userLocalStorage";
import PaymentSetup from "./pages/PaymentSetup";
import Profile from "./pages/Profile";
import { getUserAsync, selectUsers } from "./redux/userReducer";
import { authStorage, getCname } from "./utils";
import ContactImport from "./pages/Settings/ContactImport/ContactImport";
import ContactImportHistory from "./pages/Settings/ContactImport/ContactImportHistory";
import AutomatedResponses from "./pages/AutomatedResponses/AutomatedResponses";
import FirstContact from "./pages/AutomatedResponses/FirstContact";
import ChatWidget from "./components/ChatWidget";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import "./styles/antd.less";
import "./styles/tailwind.css";
import "./App.less";
import Compliance from "./pages/Compliance";
import { useModal } from "./hook/useModal";
import A2pPlanModal from "./pages/Compliance/components/A2pPlanModal";
import PendingStatusModal from "./pages/Compliance/components/PendingStatusModal";
import AllUsers from "./pages/Users";
import EditUser from "./pages/Users/editUser";

function App() {
  const [savedAuth, setAuth] = useLocalStorage(STORAGE_AUTH_KEY);
  const [createdAt, setCreatedAt] = useState();
  const { user } = useSelector(selectUsers);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const {
    close: closePendingStateModal,
    show: showPendingStateModal,
    visible: pendingStatusModalVisible,
  } = useModal();

  const handleCancelPendingStateModal = () => {
    closePendingStateModal();
  }

  const handlePendingStateModal = () => {};

  // test deployment
  useEffect(() => {
    if (savedAuth) {
      dispatch(getUserAsync());
    }
  }, [savedAuth]);

  useEffect(() => {
    if (
      user &&
      user.id &&
      (!user?.isEnabledBuyPlan || !user?.phoneSystem?.length)
    ) {
      navigate("/payment-setup");
    }
  }, [user]);

  const isAuth = authStorage.get();
  const cname = getCname();

  if (cname) {
    return (
      <main>
        <ConfigProvider
          locale={en}
          areaMapper={(area) => {
            return {
              ...area,
              emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
            };
          }}
        >
          <Routes>
            <Route path="/thank-you" element={<PublicThankYouSubmission />} />
            <Route path="/f/:id" element={<PublicFormSumission />} />
            <Route path="*" element={<PublicFormSumission />} />
          </Routes>
        </ConfigProvider>
      </main>
    );
  }

  if (isAuth) {
    return (
      <main>
        <ConfigProvider
          locale={en}
          areaMapper={(area) => {
            return {
              ...area,
              emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
            };
          }}
        >
          <Routes>
            <Route path="/thank-you" element={<PublicThankYouSubmission />} />
            <Route path="/f/:id" element={<PublicFormSumission />} />
            <Route path="/settings/profile" element={<Profile />} />
            <Route path="/payment-setup" element={<PaymentSetup />} />
            <Route path="/compliance" element={<Compliance />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/message" element={<Message />} />
            <Route path="/message/:messageId" element={<MessageDetail />} />

            <Route path="/settings/tags" element={<TagsManage />} />
            <Route path="/settings/forms" element={<FormManage />} />
            <Route path="/settings/forms/new" element={<AddNewForm />} />
            <Route path="/settings/forms/edit/:id" element={<AddNewForm />} />
            <Route
              path="/settings/csv-contacts-import"
              element={<ContactImport />}
            />
            <Route
              path="/settings/csv-contacts-import-history"
              element={<ContactImportHistory />}
            />

            <Route path="/automation/new" element={<AddNewAutomation />} />
            <Route path="/automation/edit/:id" element={<AddNewAutomation />} />
            {/* explore | new | active */}
            <Route path="/automation/:tabname" element={<Automation />} />

            <Route path="/updates" element={<UpdatesDashboard />} />
            <Route path="/contacts" element={<AllUsers />} />
            <Route
              path="/updates/scheduled/:updatesId"
              element={<AddNewUpdates />}
            />
            <Route path="/updates/scheduled/new" element={<AddNewUpdates />} />
            <Route
              path="/updates/detail/:updatesId"
              element={<EditUpdates />}
            />

            <Route
              path="/automated-responses"
              element={<AutomatedResponses />}
            />
            <Route
              path="/automated-responses/first-contact"
              element={<FirstContact />}
            />

            <Route path="*" element={<Navigate to="/message" replace />} />
          </Routes>
          <PendingStatusModal
          handleCancel={handleCancelPendingStateModal}
          handleClose={closePendingStateModal}
          handleOk={handlePendingStateModal}
          visible={pendingStatusModalVisible}
          data={createdAt}
        />
        </ConfigProvider>
        <ChatWidget />
      </main>
    );
  }

  return (
    <main>
      <ConfigProvider
        locale={en}
        areaMapper={(area) => {
          return {
            ...area,
            emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
          };
        }}
      >
        <Routes>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/confirmation" element={<SignUpConfirmation />} />
          <Route
            path="/forgot-password/reset"
            element={<ForgotPasswordConfirmation />}
          />
          <Route path="/login" element={<Login planModal={showPendingStateModal} setCreatedAt={setCreatedAt}  />} />
          <Route path="/thank-you" element={<PublicThankYouSubmission />} />
          <Route path="/f/:id" element={<PublicFormSumission />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </ConfigProvider>
      <ChatWidget />
    </main>
  );
}

export default App;

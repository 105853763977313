import { Button, Col, Form, Modal, Row, Table } from "antd";
import React from "react";

// import "./styles.less";

const ClickThroughModal = ({ visible, handleCancel, title, data }) => {

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      render: (text, record) => (
        <span>{`${record.firstName} ${record.lastName}`}</span>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      render: (text, record) => <span>{record?.phoneNumber?.phone}</span>,
    },
  ];

  return (
    <Modal
        key="ClickThroughModal"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      destroyOnClose={true}
      centered
      className="automation-trigger-modal key-response-modal"
    >
      <h3 className="font-bold text-center text-2xl mb-9">{title}</h3>
      

      <div>
        <Table
          className=" rounded-3xl"
          columns={columns}
          dataSource={data}
          locale={{
            emptyText: (
              <span className="font-bold my-10 block">
                No Contacts Found
              </span>
            ),
          }}
        />
      </div>

      <Row justify="space-around" className="mt-12">
        <Col>
          <Form.Item noStyle>
            <Button
              className="md:min-w-200"
              type="primary"
              size="large"
              onClick={handleCancel}
              block
            >
              Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};

export default ClickThroughModal;

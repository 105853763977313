import React, { useEffect, useRef, useState } from "react";

import data from "@emoji-mart/data";
import { Picker } from "emoji-mart";
// import Picker from '@emoji-mart/react'
import { Popover } from "antd";
import { EmojiIcon } from "../../assets/svg";
// onEmojiSelect={console.log}
const EmojiPicker = (props) => {
  const ref = useRef();
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if(toggle) new Picker({ ...props, data, ref });
  }, [toggle]);

 
  const handleOpenChange = (newOpen) => {
    setToggle(newOpen);
  };

  return (
    <Popover
    content={<div className={props.className || ""} onClick={() => setToggle(false)} ref={ref} />}
    trigger="click"
    placement={'top'}
    open={toggle}
    onOpenChange={handleOpenChange}
  >
    <EmojiIcon onClick={()=> setToggle(false)} />
  </Popover>
  )

  // return <div className={props.className || ""} ref={ref} />;
};

export default EmojiPicker;

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
  notification,
} from "antd";
import React from "react";
import { InputPhone } from "../../../../components";
import { handleCallAPI } from "../../../../redux/helpers";
import {
  isoCountryCodeValidator,
  messageFlowValidator,
  phoneValidator,
  regionValidator,
} from "../../../../utils";
import {
  CountryCodes,
  businessInsdustry,
  businessRegionsOfOperation,
  businessRegistrationIdentifier,
  businessTypes,
  campaignUseCases,
  companyTypes,
  jobPosition,
} from "../../../../utils/constants";
import { EMAIL_REGEX } from "../../../../utils/validations";
import { QuestionCircleOutlined } from "@ant-design/icons";

const StandardForm = ({ planType, handleCancel }) => {
  const [form] = Form.useForm();
  const [identifier, setIdentifier] = React.useState(
    businessRegistrationIdentifier[0]
  );
  const [companyType, setCompanyType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { Option } = Select;

  const onSubmitForm = async (values) => {
    if (loading) return;
    setLoading(true);

    const useCase = values.usAppToPersonUsecase
    .split(" (")[0]
    .toUpperCase();
    
    const updatedValues = {
      ...values,
      businessRegistrationNumber: {
        number: values.business_registration_number,
        identifier,
      },
      messageSample: [values.messageSample1, values.messageSample2],
      usAppToPersonUsecase: useCase.replaceAll(" ", "_"),
      // usAppToPersonUsecase: planType === 'starter' ? 'MIXED' : values.usAppToPersonUsecase
      //   .split(" (")[0]
      //   .toUpperCase(),
      business_regions_of_operation: "USA AND CANADA",
      planType,
    };


    try {
      const payload = {
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE_URL}/a2p-registration/trusthub`,
        data: updatedValues,
      };
      const trustHubRes = await handleCallAPI(payload);

      if (trustHubRes?.response?.status === "Brand Created Successfully") {
        handleCancel();
        notification.success({
          title: "Action Successful",
          message: trustHubRes?.response?.message || `Registration Successful.`,
        });
      }

      if (trustHubRes?.errors) {
        notification.error({
          title: "Action failed",
          message: trustHubRes.errors || `Registration Failed.`,
        });
        return;
      }

      if (trustHubRes?.response?.status !== "compliant") {
        trustHubRes?.response?.results?.map((item) => {
          item.fields.map((field) => {
            if (!field.passed) {
              form.setFields([
                {
                  name: field.object_field,
                  errors: [field.failure_reason],
                },
              ]);
            }
          });
        });
        return;
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const selectBefore = (
    <Select value={identifier} onChange={setIdentifier}>
      {businessRegistrationIdentifier.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      })}
    </Select>
  );

  return (
    <div className="col-span-2 mt-3">
      <h1 className="mb-2">Trust Hub registration</h1>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onSubmitForm}
        layout="vertical"
        initialValues={{
          phoneNumber: {
            phone: undefined,
            code: 1,
            short: "US",
          },
        }}
        style={{
          maxHeight: "310px",
          overflowY: "auto",
          padding: "0 20px",
        }}
      >
        <Row gutter={40}>
          <Col md={12} span={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Adam Smith" />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="business_name"
              label="Business Name"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="acme business" />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="business_type"
              label="Business Type"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select allowClear placeholder="Choose Business Type...">
                {businessTypes.map((option) => (
                  <Select.Option
                    key={`option-new-form-${option}`}
                    value={option}
                  >
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="business_industry"
              label="Business Industry"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select allowClear placeholder="Choose Business Industry...">
                {businessInsdustry.map((option) => (
                  <Select.Option
                    key={`option-new-form-${option}`}
                    value={option}
                  >
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="companyType"
              label="Company Type"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                allowClear
                onChange={setCompanyType}
                placeholder="Choose Company Type..."
              >
                {companyTypes.map((option) => (
                  <Select.Option
                    key={`option-new-form-${option}`}
                    value={option}
                  >
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {companyType === "public" && (
            <>
              <Col md={12} span={24}>
                <Form.Item
                  name="stockExchange"
                  label="Stock Exchange"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input size="large" placeholder="NYSE" />
                </Form.Item>
              </Col>

              <Col md={12} span={24}>
                <Form.Item
                  name="stockTicker"
                  label="Stock Ticker"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input size="large" placeholder="ACME" />
                </Form.Item>
              </Col>
            </>
          )}

          <Col md={12} span={24}>
            <Form.Item
              name="business_registration_number"
              label="Business Registration Number"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input addonBefore={selectBefore} />
            </Form.Item>
          </Col>
        

          <Col md={12} span={24}>
            <Form.Item
              name="customerEmail"
              label="email"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  validator(_, value) {
                    if (EMAIL_REGEX.test(value) || !value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The email is invalid"));
                  },
                },
              ]}
            >
              <Input
                size="large"
                onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
                placeholder="adamsmith@gmail.com"
              />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="websiteUrl"
              label="Website Url"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="www.acme.com" />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="street"
              label="street"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="1234 Market St" />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="isoCountryCode"
              label="ISO Country Code"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                isoCountryCodeValidator,
              ]}
            >
              <Select allowClear placeholder="Choose Job Position...">
                {Object.entries(CountryCodes).map(([name, data]) => (
                  <Select.Option
                    key={`option-new-form-${data.code}`}
                    value={data.code}
                  >
                    {data.code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="city"
              label="city"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="San Fransisco" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="region"
              label="region"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                regionValidator,
              ]}
            >
              <Input
                size="large"
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="NY"
              />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="postalCode"
              label="Postal Code"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  min:5,
                  message: "Postal Code must be at least 5 characters long",
                },
                {
                  max:5,
                  message: "Postal Code can't exceed 5 characters",
                }
              ]}
            >
              <Input size="large" placeholder="12345" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  min: 40, // Minimum length
                  message: "Description must be at least 40 characters long",
                },
                {
                  max: 4096, // Maximum length
                  message: "Description can't exceed 4096 characters",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Send marketing messages about sales and offers"
              />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="messageSample1"
              label="Message Sample"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  min: 40, // Minimum length
                  message: "Message Sample must be at least 20 characters long",
                },
                {
                  max: 4096, // Maximum length
                  message: "Message Sample can't exceed 1024 characters",
                },
              ]}
            >
              <Input size="large" placeholder="Message Sample 1" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="messageSample2"
              label="Message Sample"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  min: 40, // Minimum length
                  message: "Message Sample must be at least 20 characters long",
                },
                {
                  max: 4096, // Maximum length
                  message: "Message Sample can't exceed 1024 characters",
                },
              ]}
            >
              <Input size="large" placeholder="Message Sample 2" />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="usAppToPersonUsecase"
              label="Campaign Use Case"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select allowClear placeholder="Choose Campaign Use case...">
                {campaignUseCases.map((option) => {
                  const optionValue = option.split(" (")[0];
                  if (planType === "standard" && optionValue === "Mixed") {
                    return null;
                  } else {
                    return (
                      <Select.Option
                        key={`option-new-form-${option}`}
                        value={option}
                      >
                        {option}
                      </Select.Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              name="messageFlow"
              // label="Opt-in Information"
              label={
                <div className="relative">
                  Opt-in Information&nbsp;
                  <Tooltip
                    className="absolute top-[-7px] right-[-15px]"
                    placement="right"
                    title="End users opt-in by visiting www.acmesandwich.com and adding their phone number. They then check a box agreeing to receive text messages from Acme Sandwiches. Additionally, end users can also opt-in by texting START to (111) 222-3333 to opt in. Term and Conditions at www.acmesandwich.com/tc. Privacy Policy at www.acmesandwich.com/privacy"
                  >
                    <QuestionCircleOutlined className="block" />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                messageFlowValidator,
              ]}
            >
              <Input
                size="large"
                placeholder="End users opt-in by visiting www.acme.com, creating a new user account, consenting to receive marketing messages via text, and providing a valid mobile phone number."
              />
            </Form.Item>
          </Col>
          <div className="w-[100%] flex justify-center">
            <div className="w-[98%]">
              <Divider style={{ borderColor: "#000" }} />
            </div>
          </div>

          {/* AUTHORIZED REPRESENTATIVE INFO  */}

          <Col md={24} span={24}>
            <h2 className="text-2xl font-bold mb-5">
              AUTHORIZED REPRESENTATIVE INFORMATION
            </h2>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Adam " />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Smith" />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="email"
              label="email"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  validator(_, value) {
                    if (EMAIL_REGEX.test(value) || !value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The email is invalid"));
                  },
                },
              ]}
            >
              <Input
                size="large"
                onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
                placeholder="adamsmith@gmail.com"
              />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="jobPosition"
              label="Job Position"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select allowClear placeholder="Choose Job Position...">
                {jobPosition.map((option) => (
                  <Select.Option
                    key={`option-new-form-${option}`}
                    value={option}
                  >
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="phoneNumber"
              label="Phone"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                phoneValidator,
              ]}
            >
              <InputPhone />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              name="businessTitle"
              label="Business Title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="ceo" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="w-48	"
              >
                {!loading ? "Save" : <Spin />}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default StandardForm;

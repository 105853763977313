import { formatOptions } from "./";
export const UPDATE_TRIGGER_TYPE = [
  {
    label: "Once",
    value: "Once",
    // value: "ONCE",
  },
  {
    label: "Every day",
    value: "Every day",
    // value: "EVERY_DAY",
  },
  {
    label: "Every other day",
    value: "Every other day",
    // value: "EVERY_OTHER_DAY",
  },
  {
    label: "Every week",
    value: "Every week",
    // value: "EVERY_WEEK",
  },
  {
    label: "Every other week",
    value: "Every other week",
    // value: "EVERY_OTHER_WEEK",
  },
  {
    label: "Every month",
    value: "Every month",
    // value: "EVERY_MONTH",
  },
  {
    label: "Every 3 months",
    value: "Every 3 months",
    // value: "EVERY_3_MONTHS",
  },
  {
    label: "Every year",
    value: "Every year",
    // value: "EVERY_YEAR",
  },
];

export const RECIPIENTS_TYPE = formatOptions([
  "All Contacts",
  "Birthdays Today",
  "Birthdays This Week",
  "Birthdays This Month",
  "Is Customer",
  "Isn't Customer",
  "Is VIP",
  "Isn't VIP",
  "Female Contacts",
  "Male Contacts",
  "Has Gender",
  "Has no Gender",
  "Contact is Hidden",
  "Contact isn't Hidden",
  "Contact is Archived",
  "Contact isn't Archived",
  "Is Subscribed",
  "Isn't Subscribed",
  "Is Facebook Contact",
  "Isn't Facebook Contact",
  "Email",
  "Doesn't Have Email",
  "Job Title",
  "Doesn't Have Job Title",
  "Company",
  "Doesn't Have Company",
  "Industry",
  "Doesn't Have Industry",
  "Added This Week",
  "Added Last Week",
  "Added This Month",
  "Added Last Month",
  "Added This Year",
  "Contacted This Week",
  "Contacted Last Week",
  "Contacted This Month",
  "Contacted Last Month",
  "Contacted This Year",
  "Received Latest Update",
]);

export const LIVE_IN_TYPE = [
  // "California",
  // "New York",
  // "Florida",
  // "Texas",
  // "New Jersey",
  // "North Carolina",
  // "Georgia",
  // "Pennsylvania",
  // "Illinois",
  // "Maryland",
  // "Michigan",
  // "Washington",
  // "Colorado",
  // "Ohio",
  // "Tennessee",
  // "Virginia",
  // "Arizona",
  // "Massachusetts",
  // "Nevada",
  // "Connecticut",
  // "Missouri",
  // "Rhode Island",
  // "Utah",
  // "Alabama",
  // "Kansas",
  // "Louisiana",
  // "Minnesota",
  // "Nebraska",
  // "Arkansas",
  // "Washington D.C.",
  // "Hawaii",
  // "Indiana",
  // "New Mexico",
  // "Oklahoma",
  // "Oregon",
  // "South Carolina",
  // "Wisconsin",
  "United States",
  // "Nigeria",
  "Canada",
  // "United Kingdom",
  // "Ghana",
  // "South Africa",
  // "Mexico",
  // "Brazil",
  // "Gambia",
  // "Ireland",
  // "Sierra Leone",
  // "United Arab Emirates",
  // "Austria",
  // "Ecuador",
  // "Georgia",
  // "Guyana",
  // "Honduras",
  // "Jamaica",
  // "Myanmar",
  // "Malawi",
  // "Poland",
  // "Ukraine",
  // "Uganda",
  // "Venezuela, Bolivarian Republic of",
  // "Zambia",
];

import {
  SingleLineTextCFIcon,
  ParagraphCFIcon,
  SingleChoiceRadioCFIcon,
  SingleChoiceSelectCFIcon,
  CheckBoxCFIcon,
} from "../assets/svg";

export const INFO_FROM = {
  facebook: {
    key: "facebook",
    value: "facebook",
    title: "Facebook",
    name: "Facebook",
  },
  google: {
    key: "google",
    value: "google",
    title: "Google",
    name: "Google",
  },
  socialMedia: {
    key: "socialMedia",
    value: "socialMedia",
    title: "Social Media",
    name: "Social Media",
  },
};

export const STORAGE_AUTH_KEY = "auth";

export const PLANS_DATA = {
  starter: {
    key: "starter",
    code: "starter",
    name: "STARTER",
    prices: "$19.99/m",
    subcribers: 100,
    messages: 1000,
    descriptions: [
      "10¢ / Subscriber*",
      `1¢ / Message* 
      *Extra for MMS, Voice & International`,
      "Segmentation & Automation",
      "Same-day Setup",
      "Discord Member Group",
      "Basic Insights",
    ],
  },
  growth: {
    key: "growth",
    code: "growth",
    name: "GROWTH",
    prices: "$99.99/m",
    subcribers: 550,
    messages: 5500,
    descriptions: [
      "8¢ / Subscriber*",
      `1¢ / Message* 
      *Extra for MMS, Voice & International`,
      "Segmentation & Automation",
      "Same-day Setup",
      "Discord Member Group",
      "Basic Insights",
    ],
  },
  hightValue: {
    key: "hightValue",
    code: "hightValue",
    name: "HIGH VOLUME",
    prices: "$499+/m",
    subcribers: "4500+",
    messages: "45k+",
    descriptions: [
      "1¢ / Subscriber*",
      `1¢ / Message* 
      *Extra for MMS, Voice & International`,
      "Segmentation & Automation",
      "Dedicated Support",
      "Discord Member Group",
      "Advanced Analytics",
    ],
  },
};

// export const OPTION_FIELDS = [
//   "Gender",
//   "Birthday",
//   "Twitter",
//   "Instagram",
//   "LinkedIn",
//   "Job Title",
//   "Company",
//   "Industry",
// ];

export const OPTION_FIELDS = [
  "GENDER",
  "BIRTHDAY",
  "TWITTER",
  "INSTAGRAM",
  "LINKEDIN",
  "JOB",
  "TITLE",
  "COMPANY",
  "INDUSTRY",
];

export const DEFAULT_FIELDS = [
  "FIRST NAME",
  "LAST NAME",
  "PHONE NUMBER",
  "EMAIL",
  "LOCATION",
  "TAGS",
];

export const REQUIRED_FIELDS = ["FIRST NAME", "LAST NAME", "PHONE NUMBER"];

export const defaultFieldsCamelized = [
  "firstName",
  "lastName",
  "phoneNumber",
  "email",
  "location",
];

export const INDUSTRY = ["Tech", "Music", "Fashion", "Other"];

export const getDays = () => {
  const result = [];
  for (let i = 1; i <= 31; i++) {
    result.push(i);
  }
  return result;
};

export const getMonths = () => {
  const result = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];
  return result;
};

export const getYears = () => {
  const result = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  for (let i = currentYear; i > 1900; i--) {
    result.push(i);
  }
  return result;
};

export const PLACEHOLDER_COLOR = {
  primaryColor: "rgba(224, 240, 250, 1)",
  secondaryColor: "rgba(224, 240, 250, 0.5)",
};

export const CUSTOM_FIELD_TYPE = {
  INPUT: "SINGLE_TEXT",
  PARAGRAPH_TEXT: "PARAGRAPH_TEXT",
  RADIO: "RADIO",
  SELECT: "SELECT",
  CHECKBOX: "CHECK_BOXS",
};

export const GENDER_OPTIONS = ["Male", "Female", "Other"];

export const CUSTOM_FIELD_ICON = {
  INPUT: SingleLineTextCFIcon,
  PARAGRAPH_TEXT: ParagraphCFIcon,
  RADIO: SingleChoiceRadioCFIcon,
  SELECT: SingleChoiceSelectCFIcon,
  CHECKBOX: CheckBoxCFIcon,
};

export const CUSTOM_FIELD_LABEL = {
  INPUT: "single line text",
  PARAGRAPH_TEXT: "paragraph text",
  RADIO: "single choice (radio)",
  SELECT: "single choice (select)",
  CHECKBOX: "checkboxes",
};

export const CUSTOM_FIELD_OPTIONS = [
  {
    icon: CUSTOM_FIELD_ICON.INPUT,
    type: CUSTOM_FIELD_TYPE.INPUT,
    label: CUSTOM_FIELD_LABEL.INPUT,
  },
  {
    icon: CUSTOM_FIELD_ICON.PARAGRAPH_TEXT,
    type: CUSTOM_FIELD_TYPE.PARAGRAPH_TEXT,
    label: CUSTOM_FIELD_LABEL.PARAGRAPH_TEXT,
  },
  {
    icon: CUSTOM_FIELD_ICON.RADIO,
    type: CUSTOM_FIELD_TYPE.RADIO,
    label: CUSTOM_FIELD_LABEL.RADIO,
  },
  {
    icon: CUSTOM_FIELD_ICON.SELECT,
    type: CUSTOM_FIELD_TYPE.SELECT,
    label: CUSTOM_FIELD_LABEL.SELECT,
  },
  {
    icon: CUSTOM_FIELD_ICON.CHECKBOX,
    type: CUSTOM_FIELD_TYPE.CHECKBOX,
    label: CUSTOM_FIELD_LABEL.CHECKBOX,
  },
];

export const DURATION_TYPE = {
  TIME_FROM_TRIGGER: "TIME_FROM_TRIGGER",
  UNTIL_NEXT_DAY: "UNTIL_NEXT_DAY",
  UNTIL_NEXT_DAY_OF_WEEK: "UNTIL_NEXT_DAY_OF_WEEK",
  UNTIL_NEXT_DAY_OF_MONTH: "UNTIL_NEXT_DAY_OF_MONTH",
  UNTIL_DATE: "UNTIL_DATE",
};

export const DELAY_TYPE = [
  {
    label: "Time from trigger",
    value: DURATION_TYPE.TIME_FROM_TRIGGER,
  },
  {
    label: "Until Next Day",
    value: DURATION_TYPE.UNTIL_NEXT_DAY,
  },
  {
    label: "Until Next Day of Week",
    value: DURATION_TYPE.UNTIL_NEXT_DAY_OF_WEEK,
  },
  {
    label: "Until Next Day of Month",
    value: DURATION_TYPE.UNTIL_NEXT_DAY_OF_MONTH,
  },
  {
    label: "Until Date",
    value: DURATION_TYPE.UNTIL_DATE,
  },
];

export const DAY_OF_WEEK_TYPE = [
  {
    label: "Sunday",
    value: "Sunday",
  },
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
];

export const MONTH_TYPE = [
  {
    label: "Jan",
    value: "Jan",
  },
  {
    label: "Feb",
    value: "Feb",
  },
  {
    label: "Mar",
    value: "Mar",
  },
  {
    label: "Apr",
    value: "Apr",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "Jun",
    value: "Jun",
  },
  {
    label: "Jul",
    value: "Jul",
  },
  {
    label: "Aug",
    value: "Aug",
  },
  {
    label: "Sep",
    value: "Sep",
  },
  {
    label: "Oct",
    value: "Oct",
  },
  {
    label: "Nov",
    value: "Nov",
  },
  {
    label: "Dec",
    value: "Dec",
  },
];

export const AUTOMATION_STATUS = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
};

export const FORM_SETTINGS_STATUS = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
};

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const MERGE_FIELDS = [
  {
    fieldLabel: "fname",
    text: "Contact's first name",
    label: "firstName",
  },
  {
    fieldLabel: "name",
    text: "Contact's full name",
  },
  {
    fieldLabel: "mobile",
    text: "Contact's mobile name",
  },
  {
    fieldLabel: "lname",
    text: "Contact's last name",
  },
  {
    fieldLabel: "email",
    text: "Contact's email",
  }
];

export const MERGE_FIELDS_WITH_FORM = [
  {
    fieldLabel: "fname",
    text: "Contact's first name",
    label: "firstName",
  },
  {
    fieldLabel: "name",
    text: "Contact's full name",
  },
  {
    fieldLabel: "mobile",
    text: "Contact's mobile name",
  },
  {
    fieldLabel: "lname",
    text: "Contact's last name",
  },
  {
    fieldLabel: "email",
    text: "Contact's email",
  },
  {
    fieldLabel: "form",
    text: "Send a link to your default form or Select a Form",
    // label: "form",
  },
];

export const PLAN_PAYMENT_METHOD = {
  MONTHLY: "MONTHLY",
  ANNUAL: "ANNUAL",
};

export const businessTypes = [
  // "Sole Proprietorship",
  "Partnership",
  "Limited Liability Corporation",
  "Corporation",
  "Non-profit Corporation",
  "Co-operative",
  ];

  export const businessInsdustry = [
      "AGRICULTURE",
      "AUTOMOTIVE",
      "BANKING",
      "CONSUMER",
      "EDUCATION",
      "ENERGY",
      "ENGINEERING",
      "OIL AND GAS",
      "FAST MOVING CONSUMER GOODS",
      "FINANCIAL",
      "FINTECH",
      "FOOD AND BEVERAGE",
      "GOVERNMENT",
      "HEALTHCARE",
      "HOSPITALITY",
      "INSURANCE",
      "LEGAL",
      "MANUFACTURING",
      "MEDIA",
      "ONLINE",
      "RAW_MATERIALS",
      "REAL ESTATE",
      "RELIGION",
      "RETAIL",
      "JEWELRY",
      "TECHNOLOGY",
      "TELECOMMUNICATIONS",
      "TRANSPORTATION",
      "TRAVEL",
      "ELECTRONICS",
      "NOT FOR PROFIT"
  ];

  export const businessRegistrationIdentifier = [
    "EIN",
    "DUNS",
    "CCN"
  ]

  export const businessRegionsOfOperation = [
    "AFRICA",
    "ASIA",
    "EUROPE",
    "LATIN AMERICA",
    "USA AND CANADA",
  ];

  export const jobPosition = [
    "Director",
    "GM",
    "VP",
    "CEO",
    "CFO",
    "General Counsel",
    "Other",
  ]

  export const companyTypes = [
    "private",
    "public",
    "government",
    "non-profit",
  ]

  export const CountryCodes = {
    "Afghanistan": {
      code: "AF",
      full_name: "Afghanistan"
    },
    "Albania": {
      code: "AL",
      full_name: "Albania"
    },
    "Algeria": {
      code: "DZ",
      full_name: "Algeria"
    },
    "Andorra": {
      code: "AD",
      full_name: "Andorra"
    },
    "Angola": {
      code: "AO",
      full_name: "Angola"
    },
    "Antigua and Barbuda": {
      code: "AG",
      full_name: "Antigua and Barbuda"
    },
    "Argentina": {
      code: "AR",
      full_name: "Argentina"
    },
    "Armenia": {
      code: "AM",
      full_name: "Armenia"
    },
    "Australia": {
      code: "AU",
      full_name: "Australia"
    },
    "Austria": {
      code: "AT",
      full_name: "Austria"
    },
    "Azerbaijan": {
      code: "AZ",
      full_name: "Azerbaijan"
    },
    "Bahamas": {
      code: "BS",
      full_name: "Bahamas"
    },
    "Bahrain": {
      code: "BH",
      full_name: "Bahrain"
    },
    "Bangladesh": {
      code: "BD",
      full_name: "Bangladesh"
    },
    "Barbados": {
      code: "BB",
      full_name: "Barbados"
    },
    "Belarus": {
      code: "BY",
      full_name: "Belarus"
    },
    "Belgium": {
      code: "BE",
      full_name: "Belgium"
    },
    "Belize": {
      code: "BZ",
      full_name: "Belize"
    },
    "Benin": {
      code: "BJ",
      full_name: "Benin"
    },
    "Bhutan": {
      code: "BT",
      full_name: "Bhutan"
    },
    "Bolivia": {
      code: "BO",
      full_name: "Bolivia"
    },
    "Bosnia and Herzegovina": {
      code: "BA",
      full_name: "Bosnia and Herzegovina"
    },
    "Botswana": {
      code: "BW",
      full_name: "Botswana"
    },
    "Brazil": {
      code: "BR",
      full_name: "Brazil"
    },
    "Brunei": {
      code: "BN",
      full_name: "Brunei"
    },
    "Bulgaria": {
      code: "BG",
      full_name: "Bulgaria"
    },
    "Burkina Faso": {
      code: "BF",
      full_name: "Burkina Faso"
    },
    "Burundi": {
      code: "BI",
      full_name: "Burundi"
    },
    "Cabo Verde": {
      code: "CV",
      full_name: "Cabo Verde"
    },
    "Cambodia": {
      code: "KH",
      full_name: "Cambodia"
    },
    "Cameroon": {
      code: "CM",
      full_name: "Cameroon"
    },
    "Canada": {
      code: "CA",
      full_name: "Canada"
    },
    "Central African Republic": {
      code: "CF",
      full_name: "Central African Republic"
    },
    "Chad": {
      code: "TD",
      full_name: "Chad"
    },
    "Chile": {
      code: "CL",
      full_name: "Chile"
    },
    "China": {
      code: "CN",
      full_name: "China"
    },
    "Colombia": {
      code: "CO",
      full_name: "Colombia"
    },
    "Comoros": {
      code: "KM",
      full_name: "Comoros"
    },
    "Congo (Congo-Brazzaville)": {
      code: "CG",
      full_name: "Congo (Congo-Brazzaville)"
    },
    "Costa Rica": {
      code: "CR",
      full_name: "Costa Rica"
    },
    "Croatia": {
      code: "HR",
      full_name: "Croatia"
    },
    "Cuba": {
      code: "CU",
      full_name: "Cuba"
    },
    "Cyprus": {
      code: "CY",
      full_name: "Cyprus"
    },
    "Czechia (Czech Republic)": {
      code: "CZ",
      full_name: "Czechia (Czech Republic)"
    },
    "Democratic Republic of the Congo": {
      code: "CD",
      full_name: "Democratic Republic of the Congo"
    },
    "Denmark": {
      code: "DK",
      full_name: "Denmark"
    },
    "Djibouti": {
      code: "DJ",
      full_name: "Djibouti"
    },
    "Dominica": {
      code: "DM",
      full_name: "Dominica"
    },
    "Dominican Republic": {
      code: "DO",
      full_name: "Dominican Republic"
    },
    "Ecuador": {
      code: "EC",
      full_name: "Ecuador"
    },
    "Egypt": {
      code: "EG",
      full_name: "Egypt"
    },
    "El Salvador": {
      code: "SV",
      full_name: "El Salvador"
    },
    "Equatorial Guinea": {
      code: "GQ",
      full_name: "Equatorial Guinea"
    },
    "Eritrea": {
      code: "ER",
      full_name: "Eritrea"
    },
    "Estonia": {
      code: "EE",
      full_name: "Estonia"
    },
    "Eswatini (fmr. 'Swaziland')": {
      code: "SZ",
      full_name: "Eswatini (fmr. 'Swaziland')"
    },
    "Ethiopia": {
      code: "ET",
      full_name: "Ethiopia"
    },
    "Fiji": {
      code: "FJ",
      full_name: "Fiji"
    },
    "Finland": {
      code: "FI",
      full_name: "Finland"
    },
    "France": {
      code: "FR",
      full_name: "France"
    },
    "Gabon": {
      code: "GA",
      full_name: "Gabon"
    },
    "Gambia": {
      code: "GM",
      full_name: "Gambia"
    },
    "Georgia": {
      code: "GE",
      full_name: "Georgia"
    },
    "Germany": {
      code: "DE",
      full_name: "Germany"
    },
    "Ghana": {
      code: "GH",
      full_name: "Ghana"
    },
    "Greece": {
      code: "GR",
      full_name: "Greece"
    },
    "Grenada": {
      code: "GD",
      full_name: "Grenada"
    },
    "Guatemala": {
      code: "GT",
      full_name: "Guatemala"
    },
    "Guinea": {
      code: "GN",
      full_name: "Guinea"
    },
    "Guinea-Bissau": {
      code: "GW",
      full_name: "Guinea-Bissau"
    },
    "Guyana": {
      code: "GY",
      full_name: "Guyana"
    },
    "Haiti": {
      code: "HT",
      full_name: "Haiti"
    },
    "Holy See": {
      code: "VA",
      full_name: "Holy See"
    },
    "Honduras": {
      code: "HN",
      full_name: "Honduras"
    },
    "Hungary": {
      code: "HU",
      full_name: "Hungary"
    },
    "Iceland": {
      code: "IS",
      full_name: "Iceland"
    },
    "India": {
      code: "IN",
      full_name: "India"
    },
    "Indonesia": {
      code: "ID",
      full_name: "Indonesia"
    },
    "Iran": {
      code: "IR",
      full_name: "Iran"
    },
    "Iraq": {
      code: "IQ",
      full_name: "Iraq"
    },
    "Ireland": {
      code: "IE",
      full_name: "Ireland"
    },
    "Israel": {
      code: "IL",
      full_name: "Israel"
    },
    "Italy": {
      code: "IT",
      full_name: "Italy"
    },
    "Jamaica": {
      code: "JM",
      full_name: "Jamaica"
    },
    "Japan": {
      code: "JP",
      full_name: "Japan"
    },
    "Jordan": {
      code: "JO",
      full_name: "Jordan"
    },
    "Kazakhstan": {
      code: "KZ",
      full_name: "Kazakhstan"
    },
    "Kenya": {
      code: "KE",
      full_name: "Kenya"
    },
    "Kiribati": {
      code: "KI",
      full_name: "Kiribati"
    },
    "Kuwait": {
      code: "KW",
      full_name: "Kuwait"
    },
    "Kyrgyzstan": {
      code: "KG",
      full_name: "Kyrgyzstan"
    },
    "Laos": {
      code: "LA",
      full_name: "Laos"
    },
    "Latvia": {
      code: "LV",
      full_name: "Latvia"
    },
    "Lebanon": {
      code: "LB",
      full_name: "Lebanon"
    },
    "Lesotho": {
      code: "LS",
      full_name: "Lesotho"
    },
    "Liberia": {
      code: "LR",
      full_name: "Liberia"
    },
    "Libya": {
      code: "LY",
      full_name: "Libya"
    },
    "Liechtenstein": {
      code: "LI",
      full_name: "Liechtenstein"
    },
    "Lithuania": {
      code: "LT",
      full_name: "Lithuania"
    },
    "Luxembourg": {
      code: "LU",
      full_name: "Luxembourg"
    },
    "Madagascar": {
      code: "MG",
      full_name: "Madagascar"
    },
    "Malawi": {
      code: "MW",
      full_name: "Malawi"
    },
    "Malaysia": {
      code: "MY",
      full_name: "Malaysia"
    },
    "Maldives": {
      code: "MV",
      full_name: "Maldives"
    },
    "Mali": {
      code: "ML",
      full_name: "Mali"
    },
    "Malta": {
      code: "MT",
      full_name: "Malta"
    },
    "Marshall Islands": {
      code: "MH",
      full_name: "Marshall Islands"
    },
    "Mauritania": {
      code: "MR",
      full_name: "Mauritania"
    },
    "Mauritius": {
      code: "MU",
      full_name: "Mauritius"
    },
    "Mexico": {
      code: "MX",
      full_name: "Mexico"
    },
    "Micronesia": {
      code: "FM",
      full_name: "Micronesia"
    },
    "Moldova": {
      code: "MD",
      full_name: "Moldova"
    },
    "Monaco": {
      code: "MC",
      full_name: "Monaco"
    },
    "Mongolia": {
      code: "MN",
      full_name: "Mongolia"
    },
    "Montenegro": {
      code: "ME",
      full_name: "Montenegro"
    },
    "Morocco": {
      code: "MA",
      full_name: "Morocco"
    },
    "Mozambique": {
      code: "MZ",
      full_name: "Mozambique"
    },
    "Myanmar (formerly Burma)": {
      code: "MM",
      full_name: "Myanmar (formerly Burma)"
    },
    "Namibia": {
      code: "NA",
      full_name: "Namibia"
    },
    "Nauru": {
      code: "NR",
      full_name: "Nauru"
    },
    "Nepal": {
      code: "NP",
      full_name: "Nepal"
    },
    "Netherlands": {
      code: "NL",
      full_name: "Netherlands"
    },
    "New Zealand": {
      code: "NZ",
      full_name: "New Zealand"
    },
    "Nicaragua": {
      code: "NI",
      full_name: "Nicaragua"
    },
    "Niger": {
      code: "NE",
      full_name: "Niger"
    },
    "Nigeria": {
      code: "NG",
      full_name: "Nigeria"
    },
    "North Korea": {
      code: "KP",
      full_name: "North Korea"
    },
    "North Macedonia": {
      code: "MK",
      full_name: "North Macedonia"
    },
    "Norway": {
      code: "NO",
      full_name: "Norway"
    },
    "Oman": {
      code: "OM",
      full_name: "Oman"
    },
    "Pakistan": {
      code: "PK",
      full_name: "Pakistan"
    },
    "Palau": {
      code: "PW",
      full_name: "Palau"
    },
    "Palestine State": {
      code: "PS",
      full_name: "Palestine State"
    },
    "Panama": {
      code: "PA",
      full_name: "Panama"
    },
    "Papua New Guinea": {
      code: "PG",
      full_name: "Papua New Guinea"
    },
    "Paraguay": {
      code: "PY",
      full_name: "Paraguay"
    },
    "Peru": {
      code: "PE",
      full_name: "Peru"
    },
    "Philippines": {
      code: "PH",
      full_name: "Philippines"
    },
    "Poland": {
      code: "PL",
      full_name: "Poland"
    },
    "Portugal": {
      code: "PT",
      full_name: "Portugal"
    },
    "Qatar": {
      code: "QA",
      full_name: "Qatar"
    },
    "Romania": {
      code: "RO",
      full_name: "Romania"
    },
    "Russia": {
      code: "RU",
      full_name: "Russia"
    },
    "Rwanda": {
      code: "RW",
      full_name: "Rwanda"
    },
    "Saint Kitts and Nevis": {
      code: "KN",
      full_name: "Saint Kitts and Nevis"
    },
    "Saint Lucia": {
      code: "LC",
      full_name: "Saint Lucia"
    },
    "Saint Vincent and the Grenadines": {
      code: "VC",
      full_name: "Saint Vincent and the Grenadines"
    },
    "Samoa": {
      code: "WS",
      full_name: "Samoa"
    },
    "San Marino": {
      code: "SM",
      full_name: "San Marino"
    },
    "Sao Tome and Principe": {
      code: "ST",
      full_name: "Sao Tome and Principe"
    },
    "Saudi Arabia": {
      code: "SA",
      full_name: "Saudi Arabia"
    },
    "Senegal": {
      code: "SN",
      full_name: "Senegal"
    },
    "Serbia": {
      code: "RS",
      full_name: "Serbia"
    },
    "Seychelles": {
      code: "SC",
      full_name: "Seychelles"
    },
    "Sierra Leone": {
      code: "SL",
      full_name: "Sierra Leone"
    },
    "Singapore": {
      code: "SG",
      full_name: "Singapore"
    },
    "Slovakia": {
      code: "SK",
      full_name: "Slovakia"
    },
    "Slovenia": {
      code: "SI",
      full_name: "Slovenia"
    },
    "Solomon Islands": {
      code: "SB",
      full_name: "Solomon Islands"
    },
    "Somalia": {
      code: "SO",
      full_name: "Somalia"
    },
    "South Africa": {
      code: "ZA",
      full_name: "South Africa"
    },
    "South Korea": {
      code: "KR",
      full_name: "South Korea"
    },
    "South Sudan": {
      code: "SS",
      full_name: "South Sudan"
    },
    "Spain": {
      code: "ES",
      full_name: "Spain"
    },
    "Sri Lanka": {
      code: "LK",
      full_name: "Sri Lanka"
    },
    "Sudan": {
      code: "SD",
      full_name: "Sudan"
    },
    "Suriname": {
      code: "SR",
      full_name: "Suriname"
    },
    "Sweden": {
      code: "SE",
      full_name: "Sweden"
    },
    "Switzerland": {
      code: "CH",
      full_name: "Switzerland"
    },
    "Syria": {
      code: "SY",
      full_name: "Syria"
    },
    "Tajikistan": {
      code: "TJ",
      full_name: "Tajikistan"
    },
    "Tanzania": {
      code: "TZ",
      full_name: "Tanzania"
    },
    "Thailand": {
      code: "TH",
      full_name: "Thailand"
    },
    "Timor-Leste": {
      code: "TL",
      full_name: "Timor-Leste"
    },
    "Togo": {
      code: "TG",
      full_name: "Togo"
    },
    "Tonga": {
      code: "TO",
      full_name: "Tonga"
    },
    "Trinidad and Tobago": {
      code: "TT",
      full_name: "Trinidad and Tobago"
    },
    "Tunisia": {
      code: "TN",
      full_name: "Tunisia"
    },
    "Turkey": {
      code: "TR",
      full_name: "Turkey"
    },
    "Turkmenistan": {
      code: "TM",
      full_name: "Turkmenistan"
    },
    "Tuvalu": {
      code: "TV",
      full_name: "Tuvalu"
    },
    "Uganda": {
      code: "UG",
      full_name: "Uganda"
    },
    "Ukraine": {
      code: "UA",
      full_name: "Ukraine"
    },
    "United Arab Emirates": {
      code: "AE",
      full_name: "United Arab Emirates"
    },
    "United Kingdom": {
      code: "GB",
      full_name: "United Kingdom"
    },
    "United States of America": {
      code: "US",
      full_name: "United States of America"
    },
    "Uruguay": {
      code: "UY",
      full_name: "Uruguay"
    },
    "Uzbekistan": {
      code: "UZ",
      full_name: "Uzbekistan"
    },
    "Vanuatu": {
      code: "VU",
      full_name: "Vanuatu"
    },
    "Venezuela": {
      code: "VE",
      full_name: "Venezuela"
    },
    "Vietnam": {
      code: "VN",
      full_name: "Vietnam"
    },
    "Yemen": {
      code: "YE",
      full_name: "Yemen"
    },
    "Zambia": {
      code: "ZM",
      full_name: "Zambia"
    },
    "Zimbabwe": {
      code: "ZW",
      full_name: "Zimbabwe"
    }
  }

  export const campaignUseCases = [
    "2FA (Any authentication or account verification such as OTP)",
    "Account Notification (Notifications about the status of an account or related to being a part of an account)" ,
    "Customer Care (Support, account management, and other avenues of customer interactio)",
    "Delivery Notification (Information about the status of a delivery)",
    "Fraud Alert (Messaging about potential fraudulent activity such as spending alerts)",
    "Higher Education	(Message campaigns from colleges, universities, and other education institutions)",
    "Marketing (Promotional content such as sales and limited time offers)",
    "Mixed (A campaign that covers multiple use cases such as Customer Care and Delivery Notifications.  Note - mixed campaigns are likely to have lower throughput and a higher cost per message. NOTE: Low-Volume Brands are eligible for the Low-Volume Mixed use case detailed below.)",
    "Polling Voting	(For conducting polling and voting, such as customer surveys. Not for political use.)",
    "Public Service Announcement (PSAs to raise audience awareness about a given topic)",
    "Security Alert	(Notification of a compromised system software or hardware related)"
  ]

  export const BrandFeedback = [
    {
      id: 'TAX_ID',
      recommendation: [
        'Use your company’s business registration number (which is EIN for U.S. customers or the equivalent for customers outside the U.S.).',
        'Use your company’s legal name that you used in the tax filings.',
        'The information above needs to be an exact match to your company’s tax fillings. If you need this information, we recommend using the EIN lookup service or contact your legal department.'
      ]
    },
    {
      id: 'STOCK_SYMBOL',
      recommendation: [
        'Use your company’s stock symbol.',
        'Use the stock exchange where your company stock is traded (i.e. NYSE, Nasdaq).',
      ]
    },
    {
      id: 'GOVERNMENT_ENTITY',
      recommendation: [
        'If you are not a US-based government entity, you should register as a private entity.',
        'Provide a dated letter on official letterhead from a US-based government entity stating that the organization is considered an instrument of a municipal, state, or federal government entity.',
      ]
    },
    {
      id: 'NONPROFIT',
      recommendation: [
        'If you are not a US-based government entity, you should register as a private entity.',
        'Provide an IRS nonprofit determination letter or Tax Form 990 from the latest tax year.',
      ]
    },
  ]
import React, { useEffect, useState } from "react";
import LayoutComponent from "../../components/Layout";

import { Input, Table, Button, Space, Modal, notification } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { deleteUser, getAllUsers, getUserData } from "../../redux/userReducer";
import EditUser from "./editUser";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clear timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const AllUsers = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [deletionId, setDeletionId] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500); // Debounce delay of 500ms
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalText, setModalText] = useState("");
  const [userData, setUserData] = useState({});
  const [initialTagsIds, setInitialTagsIds] = useState([]);

  const handleCancel = () => {
    setDeletionId([]);
    setOpen(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setSortedInfo(sorter);
  };

  const getUsers = async () => {
    try {
      const { response } = await getAllUsers(pagination, search);
      setTableData(response?.data);
      setTotal(response?.totalNumberOfPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleEdit = async (id) => {
    try {
      const { response } = await getUserData(id);
      const stateObject = response.tags.map((obj) => ({
        id: obj.id,
        name: obj.name,
      }));
      setUserData(response);
      setInitialTagsIds(stateObject);

      setEditOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  function handleDeleteSelection(id) {
    setDeletionId([id]);
    setOpen(true);
    setSelectedRowKeys([]);
    setModalText("Are you sure you want to delete this user ?");
  }

  const handleDeleteUser = async () => {
    if (deletionId.length === 0) return;

    try {
      const { response } = await deleteUser(deletionId);
      setOpen(false);
      setDeletionId([]);
      notification.success({
        title: "Deleted",
        message: response.message,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDeleteSelectedUser = async () => {
    if (selectedRowKeys.length === 0) return;

    try {
      const { response } = await deleteUser(selectedRowKeys);
      setOpen(false);
      setSelectedRowKeys([]);
      notification.success({
        title: "Deleted",
        message: response.message,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => `${record.firstName} ${record.lastName}`,

      sorter: (a, b) => {
        const fullNameA = `${a.firstName} ${a.lastName}`;
        const fullNameB = `${b.firstName} ${b.lastName}`;
        return fullNameA.toLowerCase().localeCompare(fullNameB.toLowerCase());
      },
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",

      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text, record) =>
        `+${record.phoneNumber?.code} ${record.phoneNumber.phone}`,

      sorter: (a, b) => {
        const phoneNumberA = `${a.phoneNumber?.code} ${a.phoneNumber?.phone}`;
        const phoneNumberB = `${b.phoneNumber?.code} ${b.phoneNumber?.phone}`;
        return phoneNumberA.localeCompare(phoneNumberB);
      },
      sortOrder:
        sortedInfo.columnKey === "phoneNumber" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text, record) =>
        record.tags.length !== 0
          ? record.tags.map((tag) => tag.name).join(", ")
          : "No tags found",
      ellipsis: true,
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <Space align="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record?._id)}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteSelection(record._id)}
          />
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              setUserData(record);
              setViewOpen(true);
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getUsers();
  }, [pagination, debouncedValue, deletionId, selectedRowKeys, editOpen]);

  return (
    <>
      <Modal
        title=""
        onCancel={() => {
          setViewOpen(false);
          setUserData({});
        }}
        afterClose={() => setUserData({})}
        open={viewOpen}
        footer={null}
        closable={false}
        centered
        bodyStyle={{
          padding: "20px 30px",
        }}
      >
        <div>
          <div className="flex items-start justify-between mb-4">
            <h3 className="font-semibold text-lg">Contact Details</h3>
            <CloseOutlined
              style={{ cursor: "pointer" }}
              onClick={() => setViewOpen(false)}
            />
          </div>
          <div className="my-1 flex items-center gap-2">
            <p className="font-semibold">Email :</p>
            <p>{userData.email}</p>
          </div>

          <div className="my-1 flex items-center gap-2">
            <p className="font-semibold">Phone NUmber :</p>
            <p>
              +{userData?.phoneNumber?.code} {userData?.phoneNumber?.phone}
            </p>
          </div>

          <div className="my-1 flex items-center gap-2">
            <p className="font-semibold">Name :</p>
            <p>
              {userData.firstName} {userData.lastName}
            </p>
          </div>
          <div>
            {Object.keys(userData).length > 0
              ? Object?.entries(JSON.parse(userData?.metaData))?.map(
                  ([key, value]) => (
                    <div className="my-1 flex items-center gap-2" key={key}>
                      <p className="font-semibold">{key} :</p>
                      <p>{value}</p>
                    </div>
                  )
                )
              : null}
          </div>

          <div className="my-1 flex items-center gap-2">
            <p className="font-semibold">Tags :</p>
            <p>{userData?.tags?.map((tag) => tag?.name).join(", ")}</p>
          </div>
        </div>
      </Modal>

      <Modal
        className=""
        open={editOpen}
        onCancel={() => {
          setUserData({});
          setInitialTagsIds([]);
          setEditOpen(false);
        }}
        afterClose={() => {
          setUserData({});
          setInitialTagsIds([]);
        }}
        footer={null}
        closable={false}
        centered
        bodyStyle={{
          padding: "30px ",
        }}
      >
        <div className="flex items-start justify-between mb-4">
          <h3 className="font-semibold text-lg">Edit Contact Details</h3>
          <CloseOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserData({});
              setInitialTagsIds([]);
              setEditOpen(false);
            }}
          />
        </div>
        <EditUser
          userData={userData}
          setUserData={setUserData}
          editOpen={editOpen}
          setEditOpen={setEditOpen}
          setInitialTagsIds={setInitialTagsIds}
          initialTagsIds={initialTagsIds}
        />
      </Modal>

      <Modal
        title=""
        centered
        open={open}
        onCancel={() => setOpen(false)}
        afterClose={() => setDeletionId([])}
        closable={false}
        footer={null}
        bodyStyle={{
          padding: "20px 30px",
        }}
      >
        <h3 className="font-semibold text-lg">Delete</h3>

        <p className="text-base my-2">{modalText}</p>
        <div className="flex align-center justify-end gap-3 mt-4">
          <Button
            style={{ textTransform: "none" }}
            onClick={handleCancel}
            size="small"
          >
            Cancel
          </Button>
          {deletionId?.length > 0 && (
            <Button
              style={{ textTransform: "none" }}
              size="small"
              type="primary"
              onClick={handleDeleteUser}
            >
              Confirm
            </Button>
          )}
          {selectedRowKeys?.length > 0 && (
            <Button
              style={{ textTransform: "none" }}
              size="small"
              type="primary"
              onClick={handleDeleteSelectedUser}
            >
              Confirm
            </Button>
          )}
        </div>
      </Modal>

      <LayoutComponent className="Users-detail-page" title="Contacts">
        <div>
          <h1>Contacts</h1>
          <div className="flex items-center my-4 w-3/4 lg:w-1/4 md:w-100 sm:w-1">
            <Input
              // onChange={(e) => setSearch(e.target.value)}
              onChange={handleInputChange}
              value={search}
              placeholder="Search by email..."
              className="px-4 py-2 h-12 rounded-l-md box hover:shadow-none focus:shadow-none "
            />
            {!debouncedValue ? (
              <button
                // onClick={() => (!!search ? getUsers() : null)}
                onClick={getUsers}
                type="button"
                className="bg-primary h-12 text-white px-5 py-2 rounded-r-md"
              >
                <SearchOutlined style={{ fontSize: 20 }} />
              </button>
            ) : (
              <button
                // onClick={() => (!!search ? getUsers() : null)}
                onClick={() => setSearch("")}
                type="button"
                className="bg-primary h-12 text-white px-5 py-2 rounded-r-md"
              >
                <CloseOutlined style={{ fontSize: 20 }} />
              </button>
            )}
          </div>

          {selectedRowKeys?.length > 0 && (
            <div className="bg-primary mb-4 text-white py-2 flex justify-between items-center">
              <span
                style={{
                  marginLeft: 8,
                }}
              >
                Selected {selectedRowKeys.length} users
              </span>
              <Button
                style={{
                  backgroundColor: "transparent",
                  margin: "0 10px",
                  color: "inherit",
                  borderColor: "#fff",
                }}
                icon={
                  <DeleteOutlined style={{ fontSize: "20px", color: "#fff" }} />
                }
                // onClick={() => handleDeleteSelection(selectedRowKeys)}
                onClick={() => {
                  setModalText(
                    "Are you sure you want to delete the selected user ?"
                  );
                  setOpen(true);
                }}
              />
            </div>
          )}
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            rowKey="_id"
            pagination={{
              total,
              showSizeChanger: false,
              // pageSizeOptions: ["10", "20", "50"],
            }}
            onChange={handleChange}
          />
        </div>
      </LayoutComponent>
    </>
  );
};

export default AllUsers;

import React, { useEffect } from "react";
import { LayoutComponent } from "../../components";
import { Button, Divider, notification } from "antd";
import { LikeFilled, DislikeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { handleCallAPI } from "../../redux/helpers";
import { useModal } from "../../hook/useModal";
import A2pPlanModal from "./components/A2pPlanModal";

const Compliance = () => {
  const [status, setStatus] = React.useState("");
  const [planType, setPlanType] = React.useState("");
  const [useCase, setUseCase] = React.useState("");
  const [showErrors, setShowErrors] = React.useState(false);
  const [feedback, setFeedback] = React.useState({});

  const {
    close: closeA2pPlanModal,
    show: showA2pPlanModal,
    visible: a2pPlanModalVisible,
  } = useModal();

  const handleCancelA2pPlanModal = () => {
    closeA2pPlanModal();
    fetchA2pRegStatus();
  };

  const handleA2pPlanModal = () => {};

  const fetchA2pRegStatus = async () => {
    const payload = {
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/a2p-registration/brandStatus`,
    };
    const a2pRegistration = await handleCallAPI(payload);
    // console.log('a2pRegistration',a2pRegistration)
    if(a2pRegistration?.errors === "User not registered"){
      setStatus("Begin Registration");
      return;
    }
    if(a2pRegistration?.errors){
      setStatus("Failed");
      setShowErrors(true);
      notification.error({
        title: "Error",
        message: `${a2pRegistration.errors}`,
      })

    }
    if (a2pRegistration?.response?.status === "FAILED") {
      setStatus("Failed");
      setShowErrors(true);
      // setFeedback({
      //   type: "brand",
      //   message: a2pRegistration?.response?.errorFeedBack,
      // });
    }
    if (
      a2pRegistration?.response?.status === "USER VERIFIED" ||
      a2pRegistration?.response?.status === "APPROVED"
    ) {
      setStatus("Approved");
      setPlanType(a2pRegistration?.response?.planType);
      setUseCase(a2pRegistration?.response?.useCase);
    }

    if (a2pRegistration?.response?.status === "PENDING") {
      setStatus("Pending");
      setPlanType(a2pRegistration?.response?.planType);
      setUseCase(a2pRegistration?.response?.useCase);
    }
    if (a2pRegistration?.response?.status === "FAILED") {
      setStatus("Rejected");
      setPlanType(a2pRegistration?.response?.planType);
      setUseCase(a2pRegistration?.response?.useCase);
    }
  };

  useEffect(() => {
    // CHECKING FOR A2P REGISTRATION
    fetchA2pRegStatus();
  }, []);

  return (
    <LayoutComponent>
      <div className="container mx-auto">
        <h1>Compliance</h1>
        <div className="shadow bg-white mt-6 mb-5 rounded-md container-lg">
          <h3 className="font-semibold text-xs  px-3 py-2">
            REQUIRED FOR 10DLC
          </h3>
          <Divider className="m-0 opacity-30" />
          <div className=" py-5 px-5 flex flex-col  md:flex-row gap-6 md:gap-0 items-start justify-between  ">
            <div className="md:w-3/4 w-full ">
              <div>
                <h2 className="font-bold py-1">
                  Register your 10-digit long code
                </h2>
                <p className="font-semibold text-gray-2">
                  Avoid delivery failure and hight carrier fees by registering
                  your US phone number(s) and usecase. Registered users benefit
                  from higher and faster delivery rates.
                </p>
              </div>
              <Divider className="my-3 opacity-30" />
              <div className="flex items-center gap-2 ">
                <LikeFilled className="text-primary" />

                <p>
                  <strong>
                    Registered customers see up to 90% less message filtering.
                  </strong>
                </p>
              </div>
              <Divider className="my-3 opacity-30" />
              <div className="flex items-center gap-2 ">
                <DislikeFilled className="text-primary" />
                <p>
                  <strong>
                    Unregistered traffic is at greate risk of increased carrier
                    fees per message and increased filtering, causing delivery
                    failures.
                  </strong>
                </p>
              </div>
              <Divider className="mt-8 mb-3 opacity-30" />
              <div className="flex gap-3 ">
                <strong className="text-gray-2 w-1/7">Read</strong>
                <Link target="_blank" to="https://support.kinsend.io/what-impact-will-10dlc-have-on-me/">
                  <strong className="text-primary">
                    How will this affect me ?
                  </strong>
                </Link>
              </div>
              <Divider className="my-3 opacity-30" />

              <div className="flex gap-3 ">
                <strong className="text-gray-2 w-1/7">Read</strong>
                <Link target="_blank" to="https://support.kinsend.io/info-needed-for-10dlc-registration/">
                  <strong className="text-primary">
                    Information You'll Need when you Register.
                  </strong>
                </Link>
              </div>
              <Divider className="my-3 opacity-30" />

              <div className="flex gap-3">
                <strong className="text-gray-2">Read</strong>
                <Link target="_blank" to="https://support.kinsend.io/10dlc-campaign-types-and-special-use-cases/">
                  <strong className="text-primary">
                    Campaign Types and Special Use Cases.
                  </strong>
                </Link>
              </div>
            </div>
            <Button
              className="text-primary text-sm"
              size="large"
              onClick={() => {
                if (status === "Begin Registration") {
                  showA2pPlanModal();
                }
              }}
            >
              {status}
            </Button>
          </div>
        </div>

        {showErrors ? (
          <div className="shadow bg-white mt-6 mb-5 rounded-md container-lg">
            <h3 className="font-semibold text-xs  px-3 py-2">FAILURE REASON</h3>
            <Divider className="m-0 opacity-30" />
            <div className=" py-5 px-5 flex flex-col  md:flex-row gap-6 md:gap-0 items-start justify-between  ">
              <div className=" w-full ">
                <div>
                  <p className="font-semibold text-gray-2">
                  Your registration for 10DLC has failed. This can be for a variety of different reason. Please contact support at <a target="_blank" rel="noreferrer" href="mailto:support@kinsend.io">support@kinsend.io</a> for help to resolve this issue.
                  </p>
                </div>
              </div>
              {/* <Button
                className="text-primary text-sm"
                size="large"
                onClick={() => {
                  console.log("update profile");
                }}
              >
                Update Profile
              </Button> */}
            </div>
          </div>
        ) : null}

        <div className="shadow bg-white rounded-md ">
          <h3 className="font-semibold text-xs px-3 py-2">MY TRUST SCORE</h3>
          <Divider className=" m-0 opacity-30" />
          <div className="py-7 px-5">
            {status !== "Begin Registration" ? (
              <div className="flex md:flex-row flex-col md:items-center gap-2">
                <div className="flex items-center justify-center text-center font-semibold md:w-1/3 text-3xl border rounded-md border-gray-1 h-20">
                  <div>
                    <h2>{planType === "standard" ? "200K" : "6K"}</h2>
                    <p className="text-xs font-semibold text-gray-2">
                      US MSG SEGMENTS / DAY
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center text-center font-semibold md:w-1/3 text-3xl border rounded-md border-gray-1 h-20">
                  <div>
                    <h2>{planType === "standard" ? "225" : "3.75"}</h2>
                    <p className="text-xs font-semibold text-gray-2">
                      MSG SEGMENT / SECOND
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center text-center font-bold md:w-1/3 border rounded-md border-gray-1 h-20">
                  <div>
                    <h2 className="text-md ">
                      {planType
                        ? planType[0].toUpperCase() + planType.slice(1)
                        : null}{" "}
                      Brand :<span className="font-semibold"> {useCase}</span>
                    </h2>
                    <p className="text-xs font-semibold text-gray-2">
                      USE CASE
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="my-5">
              <h2 className="font-bold py-1">What is the TCR Trust Score?</h2>
              <Divider className="opacity-30 mt-2 my-3" />
              <p>
                A Trust Score is a score assigned by The Campaign Registry (TCR
                - a 3rd party that administers the wireless carrier's new
                registration system). TCR uses a reputation algorithm to review
                specific criteria relating to your company, and then assigns a
                “Trust Score”. The carriers use this score to represent how
                “trusted” your business is, and determine your daily messaging
                limits and throughput. The higher your Trust Score, the higher
                your daily messaging limits and throughput.&nbsp;
                <Link target="_blank" to="https://support.kinsend.io/what-impact-will-10dlc-have-on-me/">
                  <strong className="text-primary">Learn more</strong>
                </Link>
              </p>
            </div>

            <div className="my-5">
              <h2 className="font-bold py-1">
                Can I Appeal my TCR Trust Score?
              </h2>
              <Divider className="opacity-30 mt-2 my-3" />
              <p>
                TCR has provided a system to appeal your TCR Trust Score for
                additional review. These appeals can be submitted through
                Twilio, who will handle them on your behalf. This appeal entails
                a one-time fee of $40, which SuperPhone will cover at no
                additional cost. You can appeal your TCR Trust Score at a later
                time if you choose not to do so now. <br />
                There is no guarantee that your Trust Score will increase as a
                result of this additional review and it may decrease as a
                result.
                <Link target="_blank" to="mailto:support@kinsend.io">
                  <strong className="text-primary">
                    &nbsp;Please contact support to appeal for additional
                    review.
                  </strong>
                </Link>
              </p>
            </div>

            <div>
              <h2 className="font-bold py-1">
                How do I make changes to my registration or use case?
              </h2>
              <Divider className="opacity-30 mt-2 my-3" />
              <p>
                For now, you can get in touch with us so that we can help you
                update your registration.
                <Link target="_blank" to="mailto:support@kinsend.io">
                  <strong className="text-primary">
                    &nbsp;Please contact support
                  </strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <A2pPlanModal
        handleCancel={handleCancelA2pPlanModal}
        handleClose={closeA2pPlanModal}
        handleOk={handleA2pPlanModal}
        visible={a2pPlanModalVisible}
      />
    </LayoutComponent>
  );
};

export default Compliance;

import { Button, Col, Modal, Row } from "antd";
import React, { useEffect } from "react";
import StandardForm from "../StandardForm";

const A2pPlanModal = ({ visible, handleOk, handleCancel, data }) => {
  const [showForm, setShowForm] = React.useState(false);
  const [plan, setPlan] = React.useState("");

  const cardsDetails = [
    {
      heading: "Starter Plan",
      desc: "Less than 3000 segments per day",
      slug: "starter",
    },
    {
      heading: "Standard Plan",
      desc: "More than 3000 segments per day",
      slug: "standard",
    },
  ];

  return (
    <>
      <Modal
        key="TriggerModal"
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
          setShowForm(false);
        }}
        footer={null}
        closable={true}
        destroyOnClose={true}
        centered
        className="automation-trigger-modal"
        maskClosable={false}
      >
        {!showForm ? (
          <div>
            <h3 className="font-bold text-center text-2xl mb-9">
              Start A2P 10DLC Registration
            </h3>
            <p className="text-center">
              You will not be able to send messages or updates unless you get
              yourself registered{" "}
            </p>
            <Row justify="space-around" className="mt-12">
              {cardsDetails.map((val, index) => (
                <Col key={index}>
                  <div className="flex flex-col items-center text-center automation-action mt-2">
                    <span className="automation-action-label">
                      {val.heading}
                    </span>
                    <p className="mb-5 w-full text-xs">
                      <span className="font-bold">
                          {val.desc?.slice(0, 4)}
                      </span>
                      <span>{val.desc?.slice(4)} </span>
                    </p>
                    <Button
                      onClick={() => {
                        setShowForm(true);
                        setPlan(val.slug);
                      }}
                      type="primary"
                    >
                      Select
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div>
            {" "}
            <StandardForm planType={plan} handleCancel={handleCancel} />{" "}
          </div>
        )}
      </Modal>
    </>
  );
};

export default A2pPlanModal;

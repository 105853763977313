import React, { useState, useRef, useEffect, Fragment, forwardRef, useMemo } from "react";
import data from "@emoji-mart/data";
import { Button, Tooltip } from "antd";
import classnames from "classnames";
import { RichTextarea } from "rich-textarea";
import { AutomationActionMessageIcon, AutomationActionMaxMessageIcon } from "../../assets/svg";
import "./styles.less";
import { MERGE_FIELDS, MERGE_FIELDS_WITH_FORM } from "../../utils/constants";

const EditableText = forwardRef((props, ref) => {
  const {
    defaultValue,
    onChange,
    className,
    handleEnterSubmit,
    isDropdownTop,
    isFormEnable,
    value,
    setValue,
    setAttachmentUrl,
    wordLimit,
    setWordLimit,
  } = props;

  // States
  const [showDropdown, setShowDropdown] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [segments, setSegments] = useState(0);
  const [caretPosition, setCaretPosition] = useState("");
  const [newCaretPosition, setNewCaretPosition] = useState(null);
  const [desiredCaretPosition, setDesiredCaretPosition] = useState(null);
  const mergeFields = isFormEnable ? MERGE_FIELDS_WITH_FORM : MERGE_FIELDS;
  const mergeFieldsLabel = mergeFields.map((val) => `<${val.fieldLabel}>`);

  // Refs
  const textareaRef = useRef(null);
  const optionsRef = useRef(null);

  // Utility Functions
  const changeEmoji = (value) => {
    const words = value.split(" ");

    const wordsWithEmojis = words.map((word) => {
      if (word.startsWith(":") && word.endsWith(":")) {
        const emojiKeyword = word.slice(1, -1);
        const emoji = data.emojis[emojiKeyword]?.skins?.[0]?.native;

        if (emoji) {
          return emoji;
        }
      }

      return word;
    });

    // Join the words back into a string
    let message = wordsWithEmojis.join(" ");
    return message;
  };

  const insertAtCaret = (insertValue) => {
    if (!value) return;

    // Find the start of the partial merge tag
    let startPos = caretPosition - 1;
    while (startPos > 0 && value.charAt(startPos) !== '<') {
      startPos--;
    }

    const beforeCaretValue = value.substring(0, startPos);
    const afterCaretValue = value.substring(caretPosition);
    const updatedValue = beforeCaretValue + insertValue + afterCaretValue;

    return updatedValue;
  };

  const handleSelectField = (fieldSelected) => {
    if (!value) return;

    // Trim to ensure there are no trailing or leading spaces
    fieldSelected = fieldSelected.trim();

    const beforeCaretValue = value.substring(0, caretPosition);
    const lastCaretPos = beforeCaretValue.lastIndexOf("<");
    const preTypedMergeTag = beforeCaretValue.substring(lastCaretPos + 1);
    const preTypedLength = preTypedMergeTag.length;

    const updatedValue = insertAtCaret(fieldSelected);
    setValue && setValue(updatedValue);
    setShowDropdown(false);

    // Subtract the characters that were already typed in before selecting the merge tag
    const newPosition = caretPosition + fieldSelected.length - preTypedLength - 1;
    setNewCaretPosition(newPosition);

    onChange(updatedValue);
    setWordLimit(wordLimit - fieldSelected.length + 1);
  };

  // This useEffect handles the setting of the caret position in the textarea.
  useEffect(() => {
    if (textareaRef.current) {
      if (newCaretPosition !== null) {
        textareaRef.current.setSelectionRange(newCaretPosition, newCaretPosition);
        setNewCaretPosition(null);  // Reset the newCaretPosition to null after setting
      }
      if (desiredCaretPosition !== null) {
        textareaRef.current.setSelectionRange(desiredCaretPosition, desiredCaretPosition);
        setDesiredCaretPosition(null);  // Reset after setting
      }
    }
  }, [newCaretPosition, desiredCaretPosition]);

  const selectOptions = () => {
    // setShowDropdown(true)
    const mergeFields = isFormEnable ? MERGE_FIELDS_WITH_FORM : MERGE_FIELDS;
    console.log('All merge fields:', mergeFields);

    // Split the string at the caret position and then get the lastIndexOf "<" from the portion before the caret
    const beforeCaretValue = value.substring(0, caretPosition);
    const lastCaretPos = beforeCaretValue.lastIndexOf("<");
    console.log('Derived substring:', beforeCaretValue);

    const newValue = beforeCaretValue.substring(lastCaretPos + 1).trim();
    console.log('Derived substring:', newValue);
    let tempArr = [];

    mergeFields.map((val) => {
      if (val.fieldLabel.includes(newValue)) {
        tempArr.push(val);
      }
    });

    console.log("tempArr", tempArr, tempArr.length);

    if (tempArr.length !== 0) return tempArr;
    if (tempArr.length === 0 && newValue !== "") {
      setShowDropdown(false)
      return [];
    }

    return mergeFields;
  };

  const handleChange = (e) => {
    let messageValue = e.target.value.trim();
    onChange(e.target.value);

    if (messageValue === "") setShowDropdown(false);
    const valueWithEmoji = changeEmoji(e.target.value);
    const count = valueWithEmoji.length;
    setValue && setValue(valueWithEmoji);

    setSegments(Math.floor(count / 160));
    if (wordLimit === 1) {
      setWordLimit(160);
    } else {
      setWordLimit(160 - (count % 160));
    }

    const caretPos = e.target.selectionStart;
    setCaretPosition(caretPos);

    console.log("caretPOS", caretPos);

    // Check if the cursor is directly in front of a "<" or if substring starts with "<"
    const substringToCursor = e.target.value.substring(0, e.target.selectionStart);
    if (substringToCursor.endsWith("<") || substringToCursor.match(/<[^>]*$/)) {
      setShowDropdown(true);
      console.log("Merge fields", MERGE_FIELDS, MERGE_FIELDS_WITH_FORM);
      setCursor(0);
    } else {
      setShowDropdown(false);
    }
  }

  const checkCursorAndDropdown = () => {
    const cursorPos = textareaRef.current.selectionStart;

    // Check if cursor is directly after "<"
    if (value.charAt(cursorPos - 1) === "<") {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleDeleteKey = (e) => {
    const currentPos = e.target.selectionStart;
    const charAtCursor = value.charAt(currentPos);

    if (charAtCursor === "<") {
      const endPos = value.indexOf(">", currentPos);

      if (endPos !== -1) {
        const potentialMergeField = value.substring(currentPos, endPos + 1);

        const isValidMergeField = mergeFields.some(val => `<${val.fieldLabel}>` === potentialMergeField);

        if (isValidMergeField) {
          const newValue = value.substring(0, currentPos) + value.substring(endPos + 1);

          setValue && setValue(newValue);
          setShowDropdown(false);

          textareaRef.current.setSelectionRange(currentPos, currentPos);
          onChange(newValue);
          e.preventDefault();  // Add this line to prevent the default delete action.
          return;
        }
      }
    }
  }

  const handleBackspaceKey = (e) => {
    const currentPos = e.target.selectionStart;
    const charBeforeCursor = value.charAt(currentPos - 1);

    if (charBeforeCursor === ">") {
      const startPos = value.lastIndexOf("<", currentPos);

      if (startPos !== -1) {
        const potentialMergeField = value.substring(startPos, currentPos);
        const isValidMergeField = mergeFields.some(val => `<${val.fieldLabel}>` === potentialMergeField);

        if (isValidMergeField) {
          const newValue = value.substring(0, startPos) + value.substring(currentPos);
          setValue && setValue(newValue);
          setShowDropdown(false);
          setDesiredCaretPosition(startPos);  // Set the desired position
          onChange(newValue);
          e.preventDefault();
          return;
        }
      }
    }
  }

  const handleEnterKey = (e) => {
    if (!e.shiftKey && handleEnterSubmit && !showDropdown) {
      handleEnterSubmit();
      setTimeout(() => {
        setValue("");
        setAttachmentUrl && setAttachmentUrl({});
        setShowDropdown(false);
      }, 100);
      return;
    }
    if (showDropdown) {
      e.preventDefault(); // Prevent line break in textarea
      handleSelectField(`<${selectOptions()[cursor].fieldLabel}> `);
      return;
    }
  }

  const handleArrowKeys = (e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent scrolling of the page
      setCursor((prevCursor) =>
        prevCursor > 0 ? prevCursor - 1 : selectOptions().length - 1
      );
    } else if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent scrolling of the page
      setCursor((prevCursor) =>
        prevCursor < selectOptions().length - 1 ? prevCursor + 1 : 0
      );
    } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      const currentCursorPos = e.target.selectionStart;
      const substringToCursor = e.target.value.substring(0, currentCursorPos);
      // Check if substring ends with "<" or matches pattern "<... without closing >"
      if (substringToCursor.endsWith("<") || substringToCursor.match(/<[^>]*$/)) {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
      if (e.key === "ArrowLeft") {
        const selectionEnd = e.target.selectionEnd;
        // Check if character before cursor is ">"
        if (value.charAt(selectionEnd - 1) === ">") {
          for (let i = selectionEnd - 1; i >= 0; i--) {
            if (value[i] == "<") {
              textareaRef.current.setSelectionRange(i, i);
              break;
            }
          }
        }
      } else if (e.key === "ArrowRight") {
        const selectionStart = e.target.selectionStart;
        // Check if character after cursor is "<"
        if (value.charAt(selectionStart) === "<") {
          for (let i = selectionStart; i < value.length; i++) {
            if (value[i] === ">") {
              textareaRef.current.setSelectionRange(i + 1, i + 1);
              break;
            }
          }
        }
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Delete") {
      handleDeleteKey(e);
    } else if (e.key === "Backspace") {
      handleBackspaceKey(e);
    } else if (e.key === "Enter") {
      handleEnterKey(e);
    } else if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      handleArrowKeys(e);
    }
  };

  const handleMouseUp = (e) => {
    const selectionStart = e.target.selectionStart;
    let found = -1;
    for (let i = selectionStart; i < e.target.value.length; i++) {
      if (e.target.value[i] === ">") {
        found = i;
        break;
      } else if (e.target.value[i] === "<") {
        found = -1;
        break;
      }
    }
    if (found !== -1) {
      textareaRef.current.setSelectionRange(found + 1, found + 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && (event.key === "ArrowLeft" || event.key === "ArrowRight")) {
        event.preventDefault();
        return;
      }
    
      if ((event.shiftKey || event.ctrlKey) && event.key.startsWith("Arrow")) {
        event.preventDefault();
      }
    };

    const textarea = textareaRef.current;

    if (textarea) {
      textarea.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const textWithMergeTags = useMemo(() => {
    const mergeFields = isFormEnable ? MERGE_FIELDS_WITH_FORM : MERGE_FIELDS;
    const mergeFieldsLabel = mergeFields.map(val => `<${val.fieldLabel}>`);
    const mergeFieldsPattern = mergeFieldsLabel.join('|');
    const regex = new RegExp(`(${mergeFieldsPattern})`, 'g');

    const splitValue = value.split(regex);
    console.log(splitValue, mergeFields);
    return splitValue;
  }, [value, isFormEnable]);

  return (
    <div className={classnames("EditableText", className)}>
      <div className="hint">
        <div className="flex flex-col md:block">
          <Tooltip
            placement="top"
            title={
              <>
                Messages without <b>emojis & special</b> characters are sent
                in segments of <b>160 characters.</b>
              </>
            }
          >
            <Button>
              <AutomationActionMaxMessageIcon />| {wordLimit}
            </Button>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title={
              <>
                Carriers charge you for <b>every segment</b> they deliver to
                your recipient
              </>
            }
            className="mt-2 md:mt-0"
          >
            <Button>
              <AutomationActionMessageIcon style={{ marginRight: "5px" }} />
              {segments + 1}
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="EditableText-body pr-[110px] md:pr-[170px]">
        <RichTextarea
          placeholder="Compose your message..."
          ref={textareaRef}
          value={value}
          onKeyDown={handleKeyDown}
          onMouseUp={handleMouseUp}
          onClick={() => checkCursorAndDropdown()}
          onChange={(e) => {
            handleChange(e);
            setCaretPosition(e.target.selectionStart);
          }}
          rows={5}
          style={{
            width: "99%",
            backgroundColor: isDropdownTop ? "#fff" : "#F0F2F5",
            outline: "none",
            resize: "none",
            padding: 3,
            userSelect: "none",
          }}
          required={true}
          className="editable-textarea"
        >
          {() => {
            return textWithMergeTags.map((t, i) => {
              const mergeFields = isFormEnable ? MERGE_FIELDS_WITH_FORM : MERGE_FIELDS;
              const mergeFieldsLabel = mergeFields.map(val => `<${val.fieldLabel}>`);

              if (mergeFieldsLabel.includes(t)) {
                return (
                  <Fragment key={i}>
                    <span
                      style={{
                        color: "white",
                        backgroundColor: "#707070",
                        borderRadius: 5,
                      }}
                    >
                      {t}
                    </span>
                  </Fragment>
                );
              } else {
                return <span key={i}>{t}</span>;
              }
            });
          }}
        </RichTextarea>

        {showDropdown && (
          <div
            ref={optionsRef}
            className={classnames("EditableText-dropdown", {
              "EditableText-dropdown-top": isDropdownTop,
            })}
            // style={{ top: isDropdownTop ? "auto" : offsetTopDropdown + 30 }}
            style={{ top: isDropdownTop ? "auto" : 40 }}
            tabIndex={0}
          >
            {selectOptions().map(({ text, fieldLabel }, index) => (
              <div
                key={fieldLabel}
                className={classnames(
                  "EditableText-dropdown-item flex justify-between",
                  {
                    active: index === cursor,
                  }
                )}
                onClick={() => handleSelectField(`<${fieldLabel}> `)}
              >
                <span className="EditableText-dropdown-item-field font-bold">{`<${fieldLabel}>`}</span>
                <span>{text}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
);

export default EditableText;
